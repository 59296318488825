export const addBeforeUnload = () => {
  window.addEventListener("beforeunload", onUnloadListener);
};

export const removeBeforeUnload = () => {
  window.removeEventListener("beforeunload", onUnloadListener);
};

const onUnloadListener = (event) => {
  event.preventDefault();
  event.returnValue = "";
  return "";
};
