import React from "react";
import { Route, Switch } from "react-router-dom";

import routes from "routes.js";

var ps;

class Default extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //ps.destroy();
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/default") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return <Switch>{this.getRoutes(routes)}</Switch>;
  }
}

export default Default;
