import React from "react";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
class Refund extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.track("Lead");
    // ReactPixel.pageView();

    // redirect to dashboard after 3sec
    setTimeout(() => {
      window.location.href = "/admin/dashboard";
    }, 6000);
  }
  render() {
    return (
      <React.Fragment>
        <Helmet htmlAttributes>
          <title>Language Academy</title>
        </Helmet>
        <Header />
        <div className="innerPage">
          <section className="bannerOuter">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <div className="thankyou-inner">
                    <h2 className="header">
                      <b>
                        <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        &nbsp;&nbsp;Thank You for registration!
                      </b>
                    </h2>
                    <p>
                      {" "}
                      <br />
                      {/* <Link className="themeBtn" to="/auth/login">Login</Link> */}
                      <h6>
                        You are being redirected to the portal. If you are not
                        automatically redirected, please click{" "}
                        <a href="/admin/dashboard">here</a>
                      </h6>
                    </p>
                  </div>
                  <span></span>
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section id="pte-modules" className="mt-5">
          <div className="container">
            <div className="row text-center"></div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Refund;
