export function getAudioPermission(callback) {
  navigator.getUserMedia(
    { audio: true },
    () => {
      console.log("Permission Granted");
      callback && callback();
    },
    () => {
      console.log("Permission Denied");
    }
  );
}
