import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import API from "api.js";

export default function PopupScore(props) {
	const { open, setShowScorePopup, setTargetScore, targetScore } = props;

	const [isOpen, setIsOpen] = useState(open);
	const [score, setScore] = useState(targetScore);

	const toggle = () => setIsOpen(!isOpen);

	const changeHandle = (event) => {
		setScore(event.target.value);
	}

	const submit = () => {
		API({
			url: "updateTargetScore",
			method: "POST",
			data: { target_score: score }
		}).then(res => {
			setTargetScore(score);
			setShowScorePopup(false);
		}).catch(err => {
			console.log('err', err);
		});
	}

	useEffect(() => {
		if (!isOpen) setShowScorePopup(false);
	}, [isOpen]);

	return (
		<>
			{isOpen &&
				<Modal isOpen={isOpen} size="md">
					<ModalHeader toggle={toggle}>Set Desired Score</ModalHeader>
					<ModalBody>
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="speaking-addon">Set Score</span>
							</div>
							<select value={score} onChange={changeHandle} className="form-control">
								<option value="">Select</option>
								<option value="36">36+ (5 Bands)</option>
								<option value="42">42+ (5.5 Bands)</option>
								<option value="50">50+ (6 Bands)</option>
								<option value="58">58+ (6.5 Bands)</option>
								<option value="65">65+ (7 Bands)</option>
								<option value="73">73+ (7.5 Bands)</option>
								<option value="79">79+ (8 Bands)</option>
							</select>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => submit()}>Save Desired Score</Button>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}