/*global chrome*/
import PackagesTabs from 'components/Packages/Tabs';
import React from 'react';
import { Link} from 'react-router-dom';
import {store,persistor} from 'store/index';
class Packages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false
        }
    }

    componentDidMount() {
        
    }
 render(){
	return (
		<section className="featurePricing" id="packages">
            <PackagesTabs plans={this.props.plans} />
        </section>

	);
}
}

export default Packages;