import Api from "../api";
import { useState } from "react";

const useHttp = () => {
  const [response, setResponse] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const request = async (url, method = "GET", data = []) => {
    try {
      let response = await Api({
        url,
        method,
        data,
      });

      if (response.status >= 200 && response.status < 300) {
        setHasError(false);
        setResponse(response.data);
      } else {
        setHasError(true);
        setError(response);
      }
    } catch (e) {
      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      // +  catch not getting errors because here in api.js promise chain in broken  +
      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      console.log(e);
    }
  };

  return { response, error, hasError, request };
};

export default useHttp;
