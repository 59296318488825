import React, { useState, useEffect } from 'react';

export default function SpeakingResult(props) {
	const [result, setResult] = useState(null);

	useEffect(() => {
		const { resultData } = props;
		setResult(resultData);
	}, []);

	return (
		<>
			{result !== null &&
				<div className="scoreWrap">
					{Object.keys(result).map((item, i) => {
						return (
							<div className={`col-xs-12 col-sm-6 col-lg-3 d-flex flex-column justify-content-center align-items-center ${item === 'overall' ? 'total-score-box' : ''}`} key={i}>
								<h4>
									<span className={result[item].score === 0 ? 'text-danger' : (result[item].score > 0 && result[item].score < result[item].out_of ? 'text-orange' : 'text-success')}>{result[item].score}/{result[item].out_of}</span>
								</h4>
								<h3 className="m-0">{result[item].label}</h3>
							</div>
						)
					})}
				</div>
			}
		</>	
	);
}