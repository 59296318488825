import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Input,
  Col,
  Button
} from "reactstrap";



class NoMicrophone extends Component {
  constructor(props) {
    super(props);
    
  }
  componentDidMount() {
  
  }

  
  
  render() {
    return (
      <>
        <Col xs={12} className="text-center">
          <h3>Please Attach your Microphone</h3>
        </Col>
      </>
    );
  }
}

export default NoMicrophone;
