import React from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  Col,
  Row,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "components/custom.css";
import currentStatus from "components/current-status.jpg";
import completeStatus from "components/current-status-complete.jpg";
import playingStatus from "components/current-status-playing.jpg";
import completeRecord from "components/current-status-record-complete.jpg";
import currentRecording from "components/current-status-recording.jpg";
import img from "components/a.png";
import { s3base } from "api.js";
import IntroTest from "components/Question/Speaking/IntroTest.jsx";
import { store } from "store/index";
import MicRecorder from "mic-recorder-to-mp3";
import Sound from "react-sound";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
class ResourceCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: [],
      soundStatus: false,
      isBlocked: false,
      volume: 100,
      soundPer: 0,
      recordPer: 0,
      isRecording: false,
      recordingSoundStatus: false,
      btnText: "Playback",
      mic: false,
      headphone: false,
      keyboard: false,
      testDetail: false,
      currDetail: false,
      sequenceArr: [],
      index: 0,
      nextFlag: false,
      startFlag: false,
      intro: false,
      speak_inst: false,
      test_intro: false,
      modal: false,
    };
  }

  componentDidMount() {
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        this.setState({ isBlocked: false });
      },
      () => {
        console.log("Permission Denied");
        this.setState({ isBlocked: true });
      }
    );
    let arr;
    if (this.props.cat === 1) {
      arr = ["headphone", "mic"];
      this.setState({ sequenceArr: arr });
    } else if (this.props.cat === 2) {
      arr = ["keyboard"];
      this.setState({ sequenceArr: arr });
    } else if (this.props.cat === 3) {
      arr = ["headphone", "mic", "keyboard"];
      this.setState({ sequenceArr: arr });
    } else if (this.props.cat === 4) {
      arr = ["headphone", "keyboard"];
      this.setState({ sequenceArr: arr });
    } else if (this.props.cat === 5 && this.props.pending == 1) {
      arr = ["headphone", "mic", "keyboard"];
      this.setState({ sequenceArr: arr });
    } else if (this.props.cat === 5 && this.props.pending == 0) {
      arr = [
        "headphone",
        "mic",
        "keyboard",
        "test_intro",
        "speak_inst",
        "testDetail",
        "currDetail",
        "intro",
      ];
      this.setState({ sequenceArr: arr });
    }
    let flag = false;
    if (arr[this.state.index] === "keyboard") {
      flag = true;
    }
    this.setState({ [arr[this.state.index]]: true, nextFlag: flag });
  }

  onSound = () => {
    this.setState({ soundStatus: true });
  };

  offSound = () => {
    this.setState({ soundStatus: false, nextFlag: true });
  };

  handleSongFinishedPlaying = () => {
    this.setState({ soundStatus: false, nextFlag: true });
  };

  recordingFinishedPlaying = () => {
    this.setState({ recordingSoundStatus: false });
  };

  changeRange = (e) => {
    this.setState({ volume: e.target.value });
  };

  handleSongPlaying = (obj) => {
    this.setState({ soundPer: (obj.position * 100) / obj.duration });
  };

  handleRecordPlaying = (obj) => {
    this.setState({ recordPer: (obj.position * 100) / obj.duration });
  };

  nextButton = () => {
    if (this.state.index < this.state.sequenceArr.length - 1) {
      let arr = this.state.sequenceArr;
      let oldIndex = this.state.index;
      let newIndex = this.state.index + 1;
      let flag = false;
      let mod = false;
      if (
        [arr[newIndex]] == "keyboard" ||
        [arr[newIndex]] == "testDetail" ||
        [arr[newIndex]] == "currDetail" ||
        [arr[newIndex]] == "test_intro" ||
        [arr[newIndex]] == "speak_inst" ||
        [arr[newIndex]] == "intro"
      ) {
        flag = true;
      }
      this.setState({
        [arr[newIndex]]: true,
        [arr[oldIndex]]: false,
        index: newIndex,
        nextFlag: flag,
        modal: mod,
      });
    } else {
      if (this.state.sequenceArr[this.state.index] == "intro") {
        this.setState({ modal: true });
      } else {
        this.setState({ startFlag: true });
      }
    }
  };

  playRecording = () => {
    if (this.state.recordingSoundStatus) {
      this.setState({
        recordingSoundStatus: false,
        btnText: "Playback",
        nextFlag: true,
      });
    } else {
      this.setState({ recordingSoundStatus: true, btnText: "Stop Playback" });
    }
  };

  nextStep = () => {
    if (this.state.index < this.state.sequenceArr.length - 1) {
      this.setState({ nextFlag: true });
    } else {
      this.setState({ startFlag: true });
    }
  };

  startRecording = () => {
    if (this.state.isBlocked) {
      alert("Please attach your microphone");
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true, blobURL: "" });
        })
        .catch((e) => console.error(e));
    }
  };

  toggleYes = () => {
    this.setState({ modal: false, startFlag: true }, function () {
      this.props.showTest();
    });
  };

  toggle = () => {
    this.setState({ modal: false });
  };

  stopRecording = () => {
    if (!this.state.isBlocked) {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          this.setState({ blobURL, isRecording: false });
        })
        .catch((e) => console.log(e));
    }
  };

  render() {
    if (
      this.state.sequenceArr[this.state.index] != "intro" &&
      this.state.index == this.state.sequenceArr.length - 1 &&
      !this.state.startFlag
    ) {
      this.setState({ startFlag: true });
    }

    console.log(this.state.headphone)
    // console.log(this.props.data)
    return (
      <>
        {this.state.modal && (
          <Modal isOpen={this.state.modal} size="sm">
            <ModalBody>
              <div>Do you want to go to next Question?</div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                className="modalCloseBtn"
                onClick={this.toggleYes}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                className="modalCloseBtn"
                onClick={this.toggle}
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <div className="content questionsArea deviceTestPage">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {this.state.headphone && (
                    <div className="col-xs-12 successContent">
                      <div className="row">
                        <div className="col-sm-8">
                          <h4 className="mt-0">Headset check</h4>
                          <h6>
                            This is an opportunity to check that your headset is
                            working correctly
                          </h6>
                          <ol>
                            <li>
                              Put your headset on and adjust it so that if it's
                              comfortably over your ears
                            </li>
                            <li>
                              When you are ready. cilck on the play button. You
                              hear a short recording
                            </li>
                            <li>
                              If you do not hear anything in your headphones
                              while the status reads Playing. Raise your hard to
                              get the alteration of the Test Administator
                            </li>
                          </ol>
                          <div className="currentStatus">
                            <p>
                              Status: Click Play to begin<br></br>Volume
                            </p>
                            <Input
                              placeholder="Volume"
                              type="range"
                              min="0"
                              defaultValue={this.state.volume}
                              step="1"
                              onInput={(e) => this.changeRange(e)}
                              max="100"
                            />
                            <br></br>
                            <Progress
                              color="info"
                              value={this.state.soundPer}
                            />
                            <div className="stop-btn">
                              <Button
                                color="success"
                                disabled={this.state.soundStatus}
                                onClick={this.onSound}
                                className="p-5px"
                                size="xs"
                              >
                                Play
                              </Button>
                              <Button
                                color="success"
                                disabled={!this.state.soundStatus}
                                onClick={this.offSound}
                                className="p-5px"
                                size="xs"
                              >
                                Stop
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="testingImgOuter">
                            <img
                              src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE2OJVN?ver=7462&q=90&m=6&h=431&w=767&b=%23FFFFFFFF&l=f&f=jpg&o=t&aim=true"
                              alt="headset"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="note w-100">
                        <ul>
                          <li>
                            During the test you will not have Play and Stop
                            buttons. The audio recording will start playing
                            automatically
                          </li>
                          <li>
                            Please do not remove your headset. You should wear
                            it throughtout the test
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {this.state.speak_inst && (
                    <div className="col-xs-12 successContent">
                      <div className="row w-100">
                        <div className="col-lg-12">
                          <h4 className="mt-0">Speaking Instructions</h4>
                          <h6>
                            Some questions require you to first listen to an
                            audio clip and then record your answer by speaking
                            clearly into the microphone.
                          </h6>
                          <div className="row speakingStatus mb-5 mt-4">
                            <div className="col-sm-4">
                              <p>
                                <b>Listening: </b>The Current Status shows how
                                long you have until the audio clip starts.
                              </p>
                              <img src={currentStatus} />
                            </div>
                            <div className="col-sm-6 pl-5">
                              <p>
                                A blue bar shows the progress of the audio clip.
                                When it reaches the right hand side the audio,
                                clip will stop playing.
                              </p>
                              <div className="row doubleImges">
                                <div className="col-sm-6">
                                  <img src={completeStatus} />
                                </div>
                                <div className="col-sm-6">
                                  <img src={playingStatus} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row speakingStatus">
                            <div className="col-sm-4">
                              <p>
                                <b>Speaking: </b>The Current Status shows how
                                long you have until the microphone opens. Start
                                speaking when the status changes to recording.
                              </p>
                              <img src={currentStatus} />
                            </div>
                            <div className="col-sm-6 pl-5">
                              <p>
                                You must finish before the progress bar reaches
                                the right hand side. If you remain silent for
                                longer than 3 seconds, the recording will stop.
                                You will not be able to re-record.
                              </p>
                              <div className="row doubleImges">
                                <div className="col-sm-6">
                                  <img src={completeRecord} />
                                </div>
                                <div className="col-sm-6">
                                  <img src={currentRecording} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.test_intro && (
                    <div className="col-xs-12 successContent">
                      <div className="row w-100">
                        <div className="col-lg-12">
                          <h4 className="mt-0">Test Introduction</h4>
                          <h6>
                            This test measures the Reading, Writing, Listening
                            and Speaking skills in English that you will need in
                            an academic setting.
                          </h6>
                          <ol>
                            <li>
                              The test is divided into 3 parts. Each part may
                              contain a number of sections. The sections are
                              individually timed. The timer will be shown in the
                              top right corner of your screen. The number of
                              items in the section will also be displayed.
                              <div className="timeBlock">
                                <div className="timeBlockInr text-right">
                                  <p>
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Time Remaining : 00:32:56
                                  </p>
                                  <p>Item 22 of 39</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              At the beginning of each part you will receive
                              instructions. These will provide details on what
                              to expect in that part of the test.
                            </li>
                            <li>
                              By clicking on the Next (N) button at the bottom
                              of each screen you confirm your answer and move to
                              the next question. If you click on Next (N) you
                              will not be able to return to the previous
                              question. You will not be able to revisit any
                              question at the end of the test.
                            </li>
                            {this.props.data.break === 0 ? (
                              <li>
                                You will be offered a break of up to 10 minutes
                                before Listening part. The break is optional.
                              </li>
                            ) : (
                              ""
                            )}
                            <li>
                              This test makes use of different varieties of
                              English, for example, British, American,
                              Australian. You can answer in the standard English
                              variety of your choice.
                            </li>
                            <li>
                              During the test, do not click on any browser
                              buttons (front, back, or reload). If you want to
                              leave in between any sections, just use Save &
                              Exit button. And if you want to leave after
                              finshing a section, just use Save and Exit button.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.mic && (
                    <div className="col-xs-12 successContent">
                      <div className="row w-100">
                        <div className="col-sm-8">
                          <h4 className="mt-0">Microphone Check</h4>
                          <h6>
                            This is an opportunity to check that your microphone
                            is working correctly
                          </h6>
                          <ol>
                            <li>
                              Make sure your headset is on and the microphone is
                              in the downward position near your mouth.
                            </li>
                            <li>
                              When you are ready, click on the Record button and
                              say "Testing, testing, one, two, three" into the
                              microphone
                            </li>
                            <li>
                              After you have spoken, cilck on the Stop button.
                              Your recording is now complete
                            </li>
                            <li>
                              Now click on the Playback button. You should
                              clearly hear yourself speaking
                            </li>
                            <li>
                              If you can not hear your voice clearly. please
                              raise your hand to get the atteration of the Test
                              Administrator
                            </li>
                          </ol>
                          <div className="currentStatus">
                            <p>Status: Click record to begin</p>
                            <Progress
                              color="info"
                              value={this.state.recordPer}
                            />
                            <div className="stop-btn">
                              <Button
                                color="success"
                                disabled={this.state.isRecording}
                                className="p-5px"
                                onClick={this.startRecording}
                                size="xs"
                              >
                                Record
                              </Button>
                              <Button
                                color="success"
                                onClick={this.playRecording}
                                className="p-5px"
                                size="xs"
                              >
                                {this.state.btnText}
                              </Button>
                              <Button
                                color="success"
                                disabled={!this.state.isRecording}
                                onClick={this.stopRecording}
                                className="p-5px"
                                size="xs"
                              >
                                Stop
                              </Button>
                            </div>
                            {this.state.isRecording && (
                              <div>
                                Recording is started, please click on stop
                                button for stop recording
                              </div>
                            )}
                          </div>
                          <div className="note">
                            <ul>
                              <li>
                                During the test, you will not have Record,
                                Playback, and Stop buttons. The voice recording
                                will start automatically.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="testingImgOuter">
                            <img
                              src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE2OJVN?ver=7462&q=90&m=6&h=431&w=767&b=%23FFFFFFFF&l=f&f=jpg&o=t&aim=true"
                              alt="headset"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.keyboard && (
                    <div className="col-xs-12 successContent">
                      <div className="col-lg-12">
                        <h4 className="mt-0">Keyboard Check</h4>
                        <h6>
                          This is an opportunity to check that you have the
                          correct keyboard.
                        </h6>
                        <div className="col-xs-12">
                          <ol>
                            <li>
                              Look at the top row of letters on the keyboard.
                            </li>
                            <li>
                              The letters should appear in this order Q W E R T
                              Y.
                            </li>
                            <li>
                              If you do not have a QWERTY keyboard, raise your
                              hand to get the attention of the Test
                              Administrator
                            </li>
                          </ol>
                          <div className="keyboardImg">
                            <img src={img} alt="keyboard" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.testDetail && (
                    <div className="col-xs-12 successContent">
                      <div className="col-lg-12">
                        <h4 className="mt-0">
                          Welcome {store.getState().user.first_name}{" "}
                          {store.getState().user.last_name}
                        </h4>
                        <div className="col-xs-12">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <th>Part</th>
                                <th>Content</th>
                                <th>Time Allowed</th>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>Speaking & writing</td>
                                <td>{this.props.data.time[0]} minutes</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Reading</td>
                                <td>{this.props.data.time[1]} minutes</td>
                              </tr>
                              {this.props.data.break === 0 ? (
                                <tr>
                                  <td colSpan={2}>Optional scheduled break</td>
                                  <td>10 minutes</td>
                                </tr>
                              ) : (
                                ""
                              )}
                              <tr>
                                <td>3</td>
                                <td>Listening</td>
                                <td>{this.props.data.time[2]} minutes</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.intro && <IntroTest />}
                  {this.state.currDetail && (
                    <div className="col-xs-12 successContent">
                      <div className="col-lg-12">
                        <h4 className="mt-0">
                          Welcome {store.getState().user.first_name}{" "}
                          {store.getState().user.last_name}
                        </h4>
                        <div className="col-xs-12">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <th>Section</th>
                                <th>No of Question</th>
                                <th>Time Allowed</th>
                              </tr>
                              <tr>
                                <td>Speaking & writing</td>
                                <td>{this.props.data.full[0].length}</td>
                                <td>{this.props.data.time[0]} minutes</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.soundStatus && (
                    <Sound
                      url={s3base + "/ptedata/ptemedia/AUD (2)_1539694341.mp3"}
                      playStatus={Sound.status.PLAYING}
                      onFinishedPlaying={this.handleSongFinishedPlaying}
                      onPlaying={this.handleSongPlaying}
                      volume={this.state.volume}
                    />
                  )}
                  {this.state.blobURL && this.state.recordingSoundStatus && (
                    <Sound
                      url={this.state.blobURL}
                      playStatus={Sound.status.PLAYING}
                      onPlaying={this.handleRecordPlaying}
                      onFinishedPlaying={this.playRecording}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs={12}>
              <div className="bottomBtns">
                <Row>
                  <Col xs={9}></Col>

                  <Col xs={3}>
                    {!this.state.startFlag && (
                      <Button
                        color="primary"
                        disabled={!this.state.nextFlag}
                        onClick={this.nextButton}
                        size="xs"
                      >
                        NEXT
                      </Button>
                    )}
                    {this.state.startFlag && (
                      <Button
                        color="primary"
                        onClick={this.props.showTest}
                        size="xs"
                      >
                        Next
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ResourceCheck;
