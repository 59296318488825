import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Alert
} from "reactstrap";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import API from '../api';
import { Redirect } from "react-router-dom";
import {userContext} from 'userContext';
import { Helmet } from "react-helmet";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { setUser, expVidPopup } from 'action/index.js';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
	return {
		user: state.user,
		expVidPopup: state.expVidPopup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => dispatch(setUser(user)),
		setExpVidPopup: (count) => dispatch(expVidPopup(count))
	};
};

class Material extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect:false,
      type:0,
      all_ok:true
    };
    this.props.history.push(this.props.match.url);
  }
  componentDidMount() {
    if(this.props.user.active_subscription.length > 0 && this.props.user.active_subscription[0].plan.is_gold === 1) {
      this.props.setExpVidPopup(0);
    }
    const fetch = async () => {
      try {
        let response = await API("/getTokens");
        if(response.status === 200) {
          console.log(response);
        } else {
          this.notify(2 ,"Something went wrong");
        }
      } catch(e) {
      }
    }

    fetch();
  }

  showQuestion(type) {
    console.log(type);
    this.setState({'redirect':true,'type':type});
  }

  notify = (type,message) => {
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53 fa fa-bell",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  render() {
    if (this.state.redirect) {
       return <Redirect to={'/admin/questionList/0/'+this.state.type} />;
    }
    return (
      <>

      <Helmet>
        <title>Top PTE Training Institute</title>
      </Helmet>
      <div className="notification-div">
        <NotificationAlert ref="notificationAlert" zIndex={9999}/>
      </div>
        {!this.state.all_ok && <ChromeCheck />}
        {this.state.all_ok && <div className="siteContent py-4 multipleCards">
                  <Row>
                    <Col className="practice-material-categories" xs={12}>
                      <Card className="mb-4">
                        <CardHeader className="material-header">
                          <CardTitle className="material-category-title speaking" tag="h4">
                            Speaking Zone
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col onClick={this.showQuestion.bind(this,1)} xs={3}>
                              <Alert color="primary">Read Aloud <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,2)} xs={3}>
                              <Alert color="primary">Repeat Sentence <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,3)} xs={3}>
                              <Alert color="primary">Describe Image <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,4)} xs={3}>
                              <Alert color="primary">Respond to Situation <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,5)} xs={3}>
                              <Alert color="primary">Answer Short Questions <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardHeader className="material-header">
                          <CardTitle className="material-category-title writing" tag="h4">
                            Writing Zone
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col onClick={this.showQuestion.bind(this,6)} xs={3}>
                              <Alert color="warning">Summarize written text <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,7)} xs={3}>
                              <Alert color="warning">Write Email <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardHeader className="material-header">
                          <CardTitle className="material-category-title reading" tag="h4">Reading Zone</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col onClick={this.showQuestion.bind(this,8)} xs={3}>
                              <Alert color="info">Multiple Type, Single Answer</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,9)} xs={3}>
                              <Alert color="info">Multiple Type, Double Answer</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,10)} xs={3}>
                              <Alert color="info">Reorder Paragraph</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,11)} xs={3}>
                              <Alert color="info">Reading Fill in the Blanks</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,12)} xs={3}>
                              <Alert color="info">Fill in the Blanks Reading & Writing</Alert>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardHeader className="material-header">
                          <CardTitle className="material-category-title listening" tag="h4">Listening Zone</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col onClick={this.showQuestion.bind(this,13)} xs={3}>
                              <Alert color="success">Summarize Spoken text <img className="ai-tag" src="/images/ai_trans.png" /></Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,14)} xs={3}>
                              <Alert color="success">MCQ Single answer</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,15)} xs={3}>
                              <Alert color="success">MCQ multiple answer</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,16)} xs={3}>
                              <Alert color="success">Listening Fill In the Blanks</Alert>
                            </Col>
                            {/* <Col onClick={this.showQuestion.bind(this,17)} xs={3}>
                              <Alert color="success">Highlight correct summary</Alert>
                            </Col> */}
                            <Col onClick={this.showQuestion.bind(this,18)} xs={3}>
                              <Alert color="success">Select Missing Word</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,19)} xs={3}>
                              <Alert color="success">Highlight Incorrect word</Alert>
                            </Col>
                            <Col onClick={this.showQuestion.bind(this,20)} xs={3}>
                              <Alert color="success">Write from Dictation</Alert>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                  </Row>
                </div>}

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Material);
