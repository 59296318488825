import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import BootstrapModal from "components/PopUps/BootstrapModal";
import { useDispatch } from "react-redux";
import { expVidPopup } from "action";

const ExplanationPopUp = ({ status, toggle, isSubscribed, expVid }) => {
  const [videoPopup, setVideoPopup] = useState(false);
  const dispatch = useDispatch();
  const nav = useHistory();

  const handleClick = () => {
    if (isSubscribed && expVid) {
      toggle(false, true);
      setVideoPopup(true);
    } else {
      dispatch(expVidPopup(10));
      nav.push("/admin/plans");
    }
  };

  const closeExlanationModel = () => {
    toggle(false);
  };

  const closeVideoModel = () => {
    setVideoPopup(false);
    closeExlanationModel();
  };

  const video = expVid ? (
    <iframe
      src={expVid}
      width="90%"
      height="100%"
      style={{ border: "none" }}
      title="E1xplanation video"
    />
  ) : (
    <></>
  );

  return (
    <>
      {status && (
        <ModalSkeleton
          status={status}
          toggle={closeExlanationModel}
          handleClick={handleClick}
          btnText="Click here"
          message="To view a Question Strategy Video for this task."
          data=""
          isSmall={true}
        />
      )}
      {/* video-popup */}
      {videoPopup && (
        <BootstrapModal
          status={videoPopup}
          toggle={closeVideoModel}
          message=""
          data={video}
          width={{ width: "61%" }}
        />
      )}
    </>
  );
};

export default ExplanationPopUp;
