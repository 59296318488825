import React,{Component} from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,Button,
  TabContent, TabPane, Nav, NavItem,CardText,NavLink as NavLink1
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { NavLink } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { connect } from 'react-redux';
import { thead, tbody } from "variables/general";
import {store,persistor} from 'store/index';
import { Helmet } from "react-helmet";
import TopNavPills from "components/SectionalTest/TopNavPills";

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => dispatch(setUser(user))
	};
};

class Mock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			activeTab:0,
			all_ok1:true,
			all_ok:true,
			count:[100,100,100,100,100],
			currentSection:"Speaking Section",
			subscriptionId:(store.getState().user.active_subscription && store.getState().user.active_subscription.length > 0)?store.getState().user.active_subscription[0].stripe_subscription_id:null,
		};
	}
	
	componentDidMount() {
		let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
		let all_ok1 = (isChrome)?true:false;
		if (navigator.getUserMedia === undefined) {
			this.setState({all_ok:false,all_ok1:false});
		} else {
			navigator.getUserMedia({ audio: true }, (devices) => {                   
				console.log('Permission Granted', devices);
				this.setState({ all_ok1: all_ok1 }); 
			}, (error) => {
				this.setState({ all_ok1: false });
				console.log('Permission Denied', error);
			});
		}

		let new_format = 0;
		if(this.props.location.pathname === '/admin/mocks/new-sectional-tests') {
			new_format = 1;
		}

		this.getMocks(new_format);
	}

	componentDidUpdate(prevProps) {
		if(this.props.location.pathname !== prevProps.location.pathname) {
			if(this.props.location.pathname === "/admin/mocks/new-sectional-tests"){
				this.getMocks(1);
			} else if(this.props.location.pathname === "/admin/mock") {
				this.getMocks(0)
			}
		}
	}

	getMocks = (new_format) => {
		API({
			method: 'GET',
			url: 'web/mock1?new_format=' + new_format,
		}).then(data => {
			let tab = 1;
			this.props.location.pathname === '/admin/mock' && (tab = 5);
			this.toggle(tab);
			this.props.setUser(data.data.user);
			let count = [100,100,100,100,100];
			//if (this.state.subscriptionId) {
			count = [data.data.user.speak_ct, data.data.user.write_ct, data.data.user.read_ct, data.data.user.listen_ct, data.data.user.full_ct ];   
			//}
			const { result } = data.data;
			const mocks = result.reduce((acc, curr) => {
				if(curr.type === 0) {
					acc.type0.push(curr);
				} else {
					// if(curr.id) {
					// 	acc.type1.unshift(curr);
					// } else {
					// 	acc.type1.push(curr);
					// }
					acc.type1.push(curr);
				}
				return acc;
			}, {type0: [], type1: []});
			const {type0, type1} = mocks;
			this.setState({data: [...type0, ...type1], count: count });
		}).catch(() => { });
	}

	toggle = tab => {
		let all_ok = true;
		if(this.state.activeTab !== tab){
			let currentSection = "";
			switch(tab) {
				case 1:
					//currentSection = "Speaking Section";
					break;
				case 2:
					//currentSection = "Writing Section";
					break;
				case 3:
					//currentSection = "Reading Section";
					break;
				case 4:
					//currentSection = "Listening Section";
					break;
				case 5:
					// code block
					break;
				default:
					// code block
			}
			this.setState({
				activeTab: tab,
				currentSection: currentSection
			}, () => {
				if ([1,5].includes(this.state.activeTab)) {
					all_ok = this.state.all_ok1;
				} else {
					all_ok = true;
				}
				this.setState({all_ok:all_ok});
			});
		}
	}

	redirectToMock = (id, event) => {
		event.preventDefault();
		const index = event.target.attributes["data-id"].value;
		const { new_format } = this.state.data[index];
		this.props.history.push({
			pathname: `/admin/${new_format ? "sectionalTest" : "test"}`,
			state: {
				id,
				pending: 0
			}
		})
	}

	redirectToFullMock = (id, event) => {
		event.preventDefault();
		this.props.history.push({
			pathname: `/admin/fulltest`,
			state: {
				id,
				pending: 0
			}
		})
	}


 
	render() {
		let i = 0;
		let title = this.props.location.pathname === '/admin/mock' ? "Full Mock Tests" : "Mock Tests";
		return (
			<>
				<Helmet>
					<title>Top PTE Training Institute</title>
				</Helmet>
				{true && 
					<div className="content">
						<Row>
							<Col xs={12}>
								<Card>
									<CardHeader>
										<CardTitle tag="h4">{title}</CardTitle>
										{false && this.state.activeTab !== 5 && 
											<Link to={`/admin/progress/${this.state.activeTab}?mock=1`}>
												<Button>Score Analysis of {this.state.currentSection}</Button>
											</Link>
										}
									</CardHeader>
									<CardBody>
										<div>
											{this.props.location.pathname !== '/admin/mocks/new-sectional-tests' &&
												<div className="hidd">
													<Nav className="nav-pills-primary nav-pills-icons justify-content-center" pills role="tablist" tabs>
														<NavItem>
															<NavLink1 className={(this.state.activeTab === 1)?'active':''} onClick={() => { this.toggle(1); }}>
																Speaking
															</NavLink1>
														</NavItem>
														<NavItem>
															<NavLink1 className={(this.state.activeTab === 2)?'active':''} onClick={() => { this.toggle(2); }} >
																Writing
															</NavLink1>
														</NavItem>
														<NavItem>
															<NavLink1 className={(this.state.activeTab === 3)?'active':''} onClick={() => { this.toggle(3); }} >
																Reading
															</NavLink1>
														</NavItem>
														<NavItem>
															<NavLink1 className={(this.state.activeTab === 4)?'active':''} onClick={() => { this.toggle(4); }} >
																Listening
															</NavLink1>
														</NavItem>
														<NavItem>
															<NavLink1 className={(this.state.activeTab === 5)?'active':''} onClick={() => { this.toggle(5); }} >
																Full Mock Test
															</NavLink1>
														</NavItem>
													</Nav>
												</div>
											}
											{this.props.location.pathname === '/admin/mocks/new-sectional-tests' &&
												<TopNavPills activeTab={this.state.activeTab} toggle={this.toggle} />
											}
										</div>
										{!this.state.all_ok && 
											<div className="mt-5"><ChromeCheck /></div>
										}
										{this.state.all_ok && 
											<>
												<Table>
													<thead className="text-primary">
														<tr>
															<th className="">Title</th>
															<th className="">Description</th>
															<th className="">Time</th>
															<th className="">Action</th>
														</tr>
													</thead>
													<tbody>
														{this.state.data.map((prop, key) => {
															if(prop.category == this.state.activeTab){
																if (prop.type === 0 || prop.id) {
																	i++;
																	return (
																		<tr key={key}>
																			<td className="">{prop.title}</td>
																			<td className="">{prop.description}</td>
																			<td className="">{prop.time}</td>
																			<td className="">{prop.category !== 5 && 
																					<a href="#" data-id={key} onClick={this.redirectToMock.bind(this, prop.id)} className="nav-link">
																						<i className="" />
																						<p data-id={key}>Take Test</p>
																					</a>
																				}
																				{prop.category === 5 && 
																					<a href="#" onClick={this.redirectToFullMock.bind(this, prop.id)} className="nav-link">
																						<i className="" />
																						<p>Take Full Test</p>
																					</a>
																				}
																			</td>
																		</tr>
																	);  
																} else {
																	return (
																		<tr key={key}>
																			<td className="">{prop.title}</td>
																			<td className="">{prop.description}</td>
																			<td className="">{prop.time}</td>
																			<td className="">
																				<Link to="/admin/plans">Subscribe to mock plan</Link>
																			</td>
																		</tr>
																	);
																}
															}
														})}
													</tbody>
												</Table>
												{/* {
													this.props.user.active_subscription.length < 1 &&
													<div style={{textAlign: "-webkit-center"}}>
														<div className="border m-4 p-3" style={{borderRadius: "50px", width: "30%"}}>
															<Link to={"/mock-plan"} style={{color: "#94C23C"}}>Purchase plan to see more mocks</Link>
														</div>
													</div>
												} */}
											</>
										}
										           
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
