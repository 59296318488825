import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Label,
  Button,
  Input
} from "reactstrap";
import {base,s3base} from "api.js";
import { Checkbox } from '@material-ui/core';
import Sound from 'react-sound';
// core components
import "components/custom.css";
import ReactHtmlParser from 'react-html-parser';
import reactStringReplace from 'react-string-replace';
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
class ListeningBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      selectedValue:'',
      soundPer:0,
      volume:100,
      correctFlag:true,
      resultObj:{},
      stopped: false,
      playbackSpeed: 1,
      soundKey: 0,
    };
  }
  componentDidMount() {
    var intervalId = setInterval(this.timer, 1000);
    this.setState({intervalId: intervalId});
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
        clearInterval(state.recordId);
        [...document.querySelectorAll('.blankBox')].map((q) => { q.value=""; });
        return {correctFlag:true,recordCount:0,soundPer:0,isRecording:false,secondCount:10,questionId: props.data.id,blobURL:'',reset:true,soundStatus:true};
      }else{
        if (!state.questionId) {
          return {correctFlag:true,questionId: props.data.id};
        }
      }

      }
  }

  timer = () => {
    if (this.state.secondCount === 1) {
      clearInterval(this.state.intervalId);

    }
    this.setState({ secondCount: this.state.secondCount -1 });
  }

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();

  }



  timerRecord = () => {
    if (this.state.recordCount === 40) {
      clearInterval(this.state.recordId);
      this.stop();
    }
    this.setState({ recordCount: this.state.recordCount + 1 });
  }

  handleChange = (event) => {
    this.props.showResult();
    let arr = [];
    [...document.querySelectorAll('.blankBox')].map((q) => { arr.push(q.value); });
    let correct = this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
    this.props.submit({'answer':arr,'correct':correct},true);
    this.setState({resultObj:{'answer':arr,'correct':correct}})
  };

  updateCorrect = () =>{
    let correct = this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
    this.props.submit({'answer':[],'correct':correct});
    this.setState({correctFlag:false,resultObj:{'answer':[],'correct':correct}});
  }

  showAnswer = () => {


      if (!this.props.resultMode) {
        let elem = document.querySelectorAll('.checkedSign');
        for (var i = 0; i <= elem.length-1; i++) {
          let id = elem[i].dataset.id;

          if (elem[i].dataset.text.trim() == document.getElementById(id).value.trim()) {
            elem[i].innerHTML= '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
          }else{
            elem[i].innerHTML = '<i class="fa fa-times" style="font-size:32px;color:red"></i>';

          }
        }
      }

  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed});
  }

  render() {
    let question ="";
    let correct ="";
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.answer) {
      this.showAnswer();
    }

    if (this.props.stopRecording) {
      this.stopTest();
    }


    /**
    * IMPORTANT
    * 1. in case of timeout secondCount condition will work.
    * 2. in case of next button clicked by user ELSE IF condition will work.
    */
    // console.log('secondcount::', this.props.secondCount);
    // if (!this.state.stopped && this.props.secondCount === 1) {
    //   console.log('secondcount == 1');
    //   this.setState({ stopped: true }, () => {
    //     console.log('Stopping test by secondCount');
    //     this.stopTest();
    //   });
    // } else if (!this.state.stopped && this.props.stopRecording) {
    //   console.log('stopRecording');
    //   this.setState({ stopped: true }, () => {
    //     console.log('Stopping test by stopRecording');
    //     this.stopTest();
    //   });
    // }
    /** ----------------------------------------------------------------- */

    if (this.props.data) {
      question = this.props.data.question;
      correct = question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
    }
    if (this.state.reset) {
      var intervalId = setInterval(this.timer, 1000);
      this.setState({intervalId: intervalId,reset:false});
    }

    return (
      <>
      <Col xs={12}>
        <h4 className="infoText">
        </h4>
      </Col>
      { !this.props.resultMode && <Col xs={12}>
        <div className="col-lg-12 text-center">
        <div class="currentStatus">
                <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
                {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }

                <br></br>
                Volume
                 <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
                 <br></br>
                <Progress color="info" value={(this.state.soundPer)} />
                {!this.props.testMode && (
                  <div className="d-flex justify-content-between">
                    <div className='stop-btn'>
                      {this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <Button color="success" onClick={this.skip} size="sm">Skip</Button> }
                    </div>
                    <div className="stop-btn">
                      <PlaybackSpeedBtns changePlaybackSpeed={this.changePlaybackSpeed} playbackSpeed={this.state.playbackSpeed} />
                    </div>
                  </div>
                )}
                </p>

              </div>
        <Col xs={12}>
          {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 && <Sound
            url={s3base + this.props.data.media_link}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            onPlaying={this.handleSongPlaying}
            volume={this.state.volume}
            playbackRate={this.state.playbackSpeed}
            key={this.state.soundKey}
          />}
        </Col>
        <Col xs={12}>
          { this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
        </Col>
      </div>
      </Col>}

      <Col xs={12}><h5>{this.props.data && !this.props.resultMode && reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (<>
                        <input type="text" id={i} spellCheck="false" autocomplete="off" onChange={(e) => { this.handleChange(e)} } className="blankBox" />{this.props.answer && <span><span className="checkedSign" style={{display: (this.props.answer)?"inline-block":"none"}} data-id={i} data-text={correct[(i-1)/2]} ></span><span className="correct" >{correct[(i-1)/2]}</span></span>}</>
        ))}</h5></Col>


      <Col xs={12}>&nbsp;</Col>
        <Col xs={12}>&nbsp;</Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Correct answer</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="correct">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Your response</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="selected-option">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
      </>
    );
  }
}

export default ListeningBlank;
