import React from "react";
import Tab from "./Tab";

const PackagesTabs = ({ plans }) => {
  const newPlans = plans.filter((plan) => plan.new === "YES");
  return (
    <div className="container packagesWrap">
      <div className="row">
        {newPlans.map((plan) => (
          <Tab key={plan.id} plan={plan} title={plan.title} price={plan.price} currency={plan.currency} />
        ))}
      </div>
    </div>
  );
};

export default PackagesTabs;
