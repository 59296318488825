import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";

class Transaction extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      chrome: false
    };
  }

  componentDidMount() {
    let chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    this.setState({ chrome: chrome });
  }
 
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Chrome browser</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                
                  {this.state.chrome && <i className="fa fa-check-circle " style={{color:'green',fontSize: '100px'}}></i>}
                  {!this.state.chrome && <React.Fragment> 
                    <i className="fa fa-times-circle " style={{color:'red',fontSize: '100px'}}></i>
                    <br />
                    <em>*Please open chrome browser for practice question(s)</em>
                    </React.Fragment>
                  }
                   
                </CardBody>
              </Card>
            </Col>
            
            
          </Row>
        </div>
      </>
    );
  }
}

export default Transaction;
