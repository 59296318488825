import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import "components/custom.css";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {store,persistor} from 'store/index';
import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import API,{base} from "api.js";
var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.type === 2) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo" style={{textAlign: "center"}}>
          
            <div className="logo-img">
              <Link to="/"><img src={`${base+store.getState().logo}`} alt="react-logo" /></Link>
            </div>
          
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="photo">
              <img src={(store.getState().user.image)?base+store.getState().user.image:avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                href="#pablo"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {store.getState().user.first_name} {store.getState().user.last_name}
                  
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  <li>
                    <NavLink to="/admin/profile" activeClassName="">
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                  </li>
                  
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>
          {this.createLinks(this.props.routes)}
            <li className={this.activeRoute('/admin' +'/videos')} >
            <NavLink to="/admin/videos" activeClassName="">              
              <>
                <i className="fa fa-youtube-play" />
                <p>{(store.getState().is_main)?"PTE Secret Strategy":"Videos"} </p>
                {store.getState().is_main && <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Videos </p>}

              </>
            
          </NavLink>
        </li>
        {store.getState().is_main && <li className={this.activeRoute('/admin/transaction')} >
                    <NavLink to="/admin/transaction" activeClassName="">
                      
                        <>
                          <i className="fa fa-retweet" />
                          <p>Transaction</p>
                        </>
                    
                  </NavLink>
                </li>}
        <li className={this.activeRoute('/admin' +'/help')} >
            <NavLink to="/admin/help" activeClassName="">
              
                <>
                  <i className="fa fa-question-circle" />
                  <p>Help</p>
                </>
            
          </NavLink>
        </li>
          
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
