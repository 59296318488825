import React from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  ModalHeader
} from "reactstrap";
import { useLocation } from 'react-router-dom'
import {store,persistor} from 'store/index';
import { NavLink as Link,Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { setToken, setUser } from 'action/index.js';
import API,{base} from "api.js";
import { isMobile } from "react-device-detect";
import MobileDetect from "mobile-detect";
const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (tok) => dispatch(setUser(tok))
   };
};
class AdminNavbar extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      logout: false,
      staffFlag:false,
      first_name: null,
      last_name: null,
      country_citizenship:null,
      country_residence:null,
      country_state:null,
      dob: null,
      email: null,
      image: null,
      phone: null,
      address: null,
      lang: null,
      center: null,
      score: null,
      listening: null,
      exam_date: null,
      overall_score: null,
      institute: null,
      modal:false,
      isLoaded: false,
      appLink: "",
      page: ""
    }

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    };

  closeModal = () => {
    this.setState({modal:false});
  }

   handleClickOutside(event) {
        if (this.wrapperRef.current !== null && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({staffFlag:false})
        }
    }

  openStaff = () =>{
    this.setState({staffFlag:!this.state.staffFlag})
  }
  
  componentDidMount() {
    API({
      method: 'GET',
      url: 'dashboard/data'
    }).then(data => {
      var first_name = data.data.user_data.first_name;
      var last_name = data.data.user_data.last_name;
      var country_citizenship = data.data.user_data.country_citizenship;
      var country_residence = data.data.user_data.country_residence;
      var country_state = data.data.user_data.country_state;
      var dob = data.data.user_data.dob;
      var email = data.data.user_data.email;
      var image = data.data.user_data.image;
      var phone = data.data.user_data.phone;
      var address = data.data.user_data.address;
      var lang = data.data.user_data.lang;
      var center = data.data.user_data.center;
      var score = data.data.user_data.score;
      var listening = data.data.user_data.listening;
      var institute = data.data.user_data.institute;
      var exam_date = data.data.user_data.exam_date;
      var speaking = data.data.user_data.speaking;
      var reading = data.data.user_data.reading;
      var writing = data.data.user_data.writing;
      var listening = data.data.user_data.listening;
      var overall_score = (speaking+reading+writing+listening)/4;
      this.setState({first_name:first_name,last_name:last_name,country_citizenship:country_citizenship,country_residence:country_residence,country_state:country_state,dob:dob,email:email,image:image,phone:phone,address:address,lang:lang,center:center,score:score,listening:listening,exam_date:exam_date,overall_score:overall_score,institute:institute, isLoaded: true});

      if(window.location.pathname.match('profile') && this.state.modal){
        this.setState({modal:false});
      }
      if (!this.state.institute && !window.location.pathname.match('profile') && !window.location.pathname.match('sub/subscription') && !this.state.modal && (this.state.center == null || !this.state.phone || !this.state.email || !this.state.country_residence || !this.state.country_state || !this.state.country_citizenship )) {
        this.setState({modal:true});
      }

    }).catch(()=>{
      
    });
    // console.log(window.location.pathname.match('profile'));
    // console.log(this.state.modal)
    if(window.location.pathname.match('profile') && this.state.modal){
      this.setState({modal:false});
    }
    window.addEventListener("resize", this.updateColor);
    document.addEventListener('mousedown', this.handleClickOutside);

    //os
    if(isMobile) {
      const md = new MobileDetect(window.navigator.userAgent);
      if (md.os() === "AndroidOS" || md.os() === "iOS") {
        if (md.os() === "AndroidOS") {
          this.setState({appLink: process.env.REACT_APP_ANDROID_URL});
        } else if (md.os() === "iOS") {
          this.setState({appLink: process.env.REACT_APP_IOS_URL});
        }
      }
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({staffFlag:false})
    }
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }

    if(this.state.isLoaded) {
      if (!this.state.institute && !window.location.pathname.match('profile') && !window.location.pathname.match('sub/subscription') && !this.state.modal && (this.state.center == null || !this.state.phone || !this.state.email || !this.state.country_residence || !this.state.country_state || !this.state.country_citizenship )) {
        console.log('here3')
        if(window.location.pathname !== this.state.page) {
          this.setState({modal:true});
          this.setState({page: window.location.pathname});
        }
      }
      // console.log(window.location.pathname.match('profile'));
      // console.log(this.state.modal)
      if(window.location.pathname.match('profile') && this.state.modal){
        this.setState({modal:false});
      }
      window.addEventListener("resize", this.updateColor);
      document.addEventListener('mousedown', this.handleClickOutside);
    }

  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  modalCloseBtn = () => {
    this.setState({modal:false});
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  logout = () => {
    cookie.remove('access-token', { path: '/' });
    this.setState({logout:true});    
  }
  render() {
    if (this.state.logout) {
      this.props.setToken(null);
      this.props.setUser(null);
      return (<Redirect to="/auth/login" />);
    }
    
    return (
      <>
      {this.state.modal && <Modal isOpen={this.state.modal} size="md">
        {/* <div className="modalHeader">
          <h2> Please complete your details</h2>
        </div> */}
        <ModalHeader style={{display: "flex"}} toggle={() => {this.setState({modal: false})}}>Please complete your details</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              
            </Col> 
          </Row>
        </ModalBody>
        <ModalFooter>
          <Link to="/admin/profile" ><Button color="secondary" className="modalCloseBtn" onClick={this.modalCloseBtn}>Complete Profile</Button></Link>
          
        </ModalFooter>
      </Modal>}
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
          style={ isMobile ? {height: "2.7%", lineHeight: 0}: {}}
        >
          {isMobile && 
            <>
              <div className="text-center w-100 m-1">
                <div className="text-center mt-1">
                  <a href={this.state.appLink}>
                    Open in app
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" className="ah"><path d="M.98 8.48a.37.37 0 1 0 .54.54l-.54-.54zm7.77-7.23h.38c0-.2-.17-.38-.38-.38v.38zM8.37 6.5a.37.37 0 1 0 .76 0h-.76zM3.5.87a.37.37 0 1 0 0 .76V.88zM1.52 9.03l7.5-7.5-.54-.54-7.5 7.5.54.54zm6.86-7.77V6.5h.74V1.25h-.74zm-4.88.38h5.25V.88H3.5v.74z" fill="currentColor"></path></svg>
                  </a>
                </div>
              </div>
              <hr style={{width: "100%", marginTop: "0rem", marginBottom: "0rem"}} />
            </>
          }
          <Container fluid className={isMobile ? "mb-2" : " "}>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                <span className="d-none d-md-block">
                  
                </span>
                <span className="d-block d-md-none"></span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              
              <Nav navbar>
                <div className="col-sm-12 text-right">
                  <span ref={this.wrapperRef} onClick={(e)=>{this.openStaff()}} className={(this.state.staffFlag)?"staff staffMenu open":"staff staffMenu"}><i className="fa fa-user" aria-hidden="true"></i> <a href="javascript:;"> <samp className="caret"></samp></a>
                      <ul className="stafDropdown">
                        <li>
                          <Link to="/admin/profile">My Profile</Link>
                        </li>
                        <li>
                          <Link to="/admin/password">Change Password</Link>
                        </li>
                        <li>
                          <a href="javascript:void(0);"  onClick={this.logout} >Logout</a>
                      </li>
                        
                      </ul>
                  </span>
                </div>
                                
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
