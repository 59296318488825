import React, { Component } from "react";

// reactstrap components
import API from "api.js";
import SidebarClose from "components/Sidebar/SidebarClose";
import Introduction from "components/StgyVideos/Introduction";
import Category from "components/StgyVideos/Category";
import { store } from "store/index";
import { setAlert } from "action";

const classes = [
  "",
  "speakingVideos",
  "writingVideos",
  "readingVideos",
  "listeningVideos",
];

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: false,
      modal1: false,
      videoPlan: true,
      loadmore: false,
      active_subscription: [],
    };
  }
  componentDidMount() {
    API({
      method: "GET",
      url: "user-data-get/active_subscription",
    })
      .then((data) => {
        let active_subscription = data.data.user.active_subscription;
        this.setState({ active_subscription: active_subscription });
        if (
          active_subscription.length > 0 &&
          active_subscription[0].package_id === 9
        ) {
          this.setState({ modal1: true });
        }
      })
      .catch(() => {});
    this.loadVideo();
  }

  loadVideo = () => {
    API({
      method: "GET",
      url: "get-stgy-videos",
    })
      .then((data) => {
        if (data.status === 200 || data.status === 207) {
          this.setState({
            data: data.data.video,
          });
          return;
        }
        store.dispatch(
          setAlert({ flag: true, type: 2, message: "Something went wrong." })
        );
      })
      .catch(() => {});
  };

  closeModal = () => {
    this.setState({ modal1: false });
  };

  render() {
    return (
      <>
        <div className="content strategyVideosCntnt">
          {/* <SidebarClose /> */}
          <div className="videosDataOuter">
            {this.state.data.map((category, index) => {
              return category.id === 1 ? (
                <Introduction
                  key={category.id}
                  title={category.title}
                  video={category.stgy_vid}
                />
              ) : (
                <Category key={category.id} containerClass={classes[index]}>
                  <Category.Title>{category.title}</Category.Title>
                  <Category.List>
                    {category.stgy_vid.map((video) => {
                      return (
                        <Category.Item
                          title={video.title}
                          thumbnail={video.thumbnail}
                          vidLink={video.link}
                          id={video.id}
                          key={video.id}
                        />
                      );
                    })}
                  </Category.List>
                </Category>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Video;
