import React from "react";
import {
  Button,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import { connect } from 'react-redux';
import "components/custom.css";
import API from "api.js";
import { Redirect, NavLink,Link } from "react-router-dom";
import { setToken, setUser, setLogo, setSub, setLoader } from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import NotificationAlert from "react-notification-alert";
import {store} from 'store/index';
import GoogleBtn from "components/GoogleBtn/GoogleBtn";

const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};

const MESSAGE = 'Multiple Logins Detected! Please Logout from other device or mobile, or Change your Password!';
const MESSAGE_ONE = 'Successfully logged out from the system.';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error_status: false,
      error_status_message: MESSAGE,
      alert_color: 'danger',
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      loginLock:false,
      taskModal:false,
      task_success_bag:'',
      task_error_bag:'',
      task_name:'',
      task_phone:'',
      task_email:'',
      task_institute:'',
      logoIcon:"/images/logol.png",
      isMainSite: (window.location.hostname.split('.').shift() === "www")?true:false
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    store.dispatch(setLoader(true));
    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('2474090032742391');
    ReactPixel.init('197333187452630');
    ReactPixel.pageView();
    document.body.classList.toggle("login-page");

    // 100ms delay. becuase at first url is /session-expired and then changes after some ms
    setTimeout(() => {
      const { location } = this.props;
      const query = location.search !== "" ? location.search.split("?")[1] : "";
      
      this.setState({
        error_status: (location.pathname === '/auth/login/session-expired' || query === 'logout') ? true : false,
        error_status_message: query === 'logout' ? MESSAGE_ONE : MESSAGE,
        alert_color: query === 'logout' ? 'success' : 'danger'
      });
    }, 100);

    if(this.props.token !== null && this.props.token !== 'null') {
      this.props.history.push('/admin/dashboard');
    }
    
    if(this.state.error_status){
      this.notify(2,this.state.error_status_message);
    }


  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  notify = (type,message) => {

    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53 fa fa-bell",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  postLogIn = async() => {
    if (this.state.loginLock) {
      return ;
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }
    this.setState({error_bag:null,loginLock:true});

    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email.trim());
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/login',
        data: bodyFormData
      })
    }catch(error) {};

    if (res === null || res === undefined) {
      // in case server not responding.
    } else if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.access_token);
      this.props.setUser(res.data.user);
      this.props.setLogo(res.data.image);
      let sub  = (res.data.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      if (res.status === 200) {
        // window.location.href  = "/admin/dashboard";
        // this.props.history.push("/admin/dashboard");
        //this.setState({login:true,loginLock:false});
      }

    } else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';

      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str,loginLock:false});

    } else if (res.response.status === 402) {
      if(res.response.data.error === 'google' || res.response.data.error === 'facebook') {
        this.setState({error_bag:`This email address is linked to a social account. Please continue with ${res.response.data.error}`,loginLock:false});
      } else if (res.response.data.error === 'DELETED') {
        this.setState({
          error_bag: res.response.data.message,
          loginLock: false
        });
      } else {
        this.setState({error_bag:"Wrong credentials.",loginLock:false});
      }
    }

  }

  post20taskRequest = async(e) => {
    e.preventDefault();
    if(this.state.task_name == '' || this.state.task_phone == '' || this.state.task_email == '' || this.state.task_institute == ''){
      this.setState({task_error_bag:'Please fill all fields!'});
    }else{
      this.setState({task_error_bag:''});
      var bodyFormData = new FormData();
      bodyFormData.set('name', this.state.task_name);
      bodyFormData.set('phone', this.state.task_phone);
      bodyFormData.set('email', this.state.task_email);
      bodyFormData.set('institute', this.state.task_institute);
      console.log(bodyFormData);
      console.log(this.state);
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: '/task-request-submit-data',
          data: bodyFormData
        })
      }catch(error) {
      };
      console.log(res);
      if (res.status === 200) {
        this.setState({task_success_bag:'Request Submitted Successfully!'});
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      }else{
        this.setState({task_error_bag:'Unable to submit request!'});
      }
    }
  }

  showTaskModal = () => {
    this.setState({taskModal:true});
  }

  closeTaskModal = () => {
    this.setState({taskModal:false});
  }

  submitForm = (e) => {
    e.preventDefault();
    this.postLogIn();
  }

  facebookSignUp = (response) => {
    console.log(response);
    API({
      method: "POST",
      url: "/fbSignUp",
      data: response
    }).then((res) => {
      console.log(res);
      this.afterRequest(res);
    }).catch((err)=> this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false}));
  };

  googleSignUp = (credentialResponse) => {
    store.dispatch(setLoader(false));
    API({
      method: "POST",
      url: "/googleSignUp",
      data: credentialResponse
    }).then((res) => {
      console.log(res)
      this.afterRequest(res);
    }).catch((err)=> {
      // this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false});
    })
  }

  afterRequest = (res) => {
    if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.original.access_token);
      this.props.setUser(res.data.original.user);
      this.props.setLogo(res.data.original.image);
      let sub  = (res.data.original.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      console.log(res.status === 200);
      if (res.status === 200) {
        console.log(res.status)
        window.location.href  = "/admin/dashboard";
        //this.setState({login:true,loginLock:false});
      }

    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';

      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str,loginLock:false});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Soemthing went wrong. Please try again later.",loginLock:false});
    }
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    const {token,setToken} = this.props;
    if (this.state.login) {
        //return (<Redirect to="/admin/dashboard" />);
    }
    if (this.props.token) {
      return (<Redirect to="/admin/dashboard" />);
    } 
    return (
      <div className="innerPage">
      <div className="login-page">
      <Helmet>
        <title>Login - Language Academy</title>
        <meta name="twitter:title" content="Login - Language Academy" />
      <meta property="og:title" content="Login - Language Academy" />
      <meta name="description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />

        <meta name="twitter:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
        <meta property="og:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
    </Helmet>
    <div className="notification-div">
      <NotificationAlert ref="notificationAlert" zIndex={9999}/>
    </div>
      <section className="bannerOuter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h1>Login to PTE Core</h1>
      </div>
    </div>
  </div>
</section>
{this.state.taskModal && <Modal isOpen={this.state.taskModal} id="anouncementPopup" size="lg" className="taskpopup">
  <ModalBody>
    <Container>
      <Row >
        <Col className="ml-auto mr-auto" lg="12" md="12">
          <button onClick={(e)=>{this.closeTaskModal()}} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <img src="/images/experienced-trainer.png" alt="mobile-img" />
          </div>
          <Form action="" className="form" method="">
            <h3 className="header text-center">Get our portal for your institute</h3>
            {this.state.task_success_bag && <Alert color="success">{this.state.task_success_bag}</Alert>}
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Name"
                type="text"
                name="task_name"
                value={this.state.task_name}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-mobile" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Phone"
                type="number"
                name="task_phone"
                value={this.state.task_phone}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                type="text"
                name="task_email"
                value={this.state.task_email}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-badge" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Institute Name"
                type="text"
                name="task_institute"
                value={this.state.task_institute}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
            </InputGroup>
            <br />
            <Button
              className="btn-round mb-3"
              color="warning"
              onClick={(e) => {this.post20taskRequest(e)}}
            >Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  </ModalBody>
</Modal>}
<section className="contentOuter">
  <form onSubmit={this.submitForm}>
  <div className="container">
    <div className="row">
      <div className="col-sm-7 loginForm">
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
        {this.state.error_status && <Alert color="danger">{this.state.error_status_message}</Alert>}
          <div className="fieldOuter">
            <Input
                placeholder="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
              {this.validator.message('Email', this.state.email.trim(), 'required|email')}
          </div>
          <div className="fieldOuter">
            <Input
                placeholder="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
              {this.validator.message('Password', this.state.password, 'required')}
          </div>
          <div className="rememberForgot">
            <div className="rememberMe">
              <input type="checkbox" name="" />
              <span>Remember me</span>
            </div>
            <div className="forgotPwd">
              <NavLink className="mt-5" to='/auth/forgot'><span>Forgot your Password?</span></NavLink>
            </div>
          </div>
          <div className="fieldOuter text-right">
            <Button
              className="themeBtn"
              onClick={(e) => {this.postLogIn()}}
            >
              Login
            </Button>
          </div>
          <div className="separator">
            <hr className="hr"/>
            <span>OR</span>
            <hr className="hr"/>
          </div>
          {/* <div className="google mb-3">
            <GoogleLogin
              text="continue_with"
              size="medium"
              width={isMobile ? "200" : "400"}
              onSuccess={this.googleSignUp}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </div> */}
          <div className="text-center">
            <GoogleBtn afterLogin={this.afterRequest}/>
          </div>
          {/* <div className="text-center fb-parent">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={this.facebookSignUp}
              icon="fa-facebook"
              cookie={false}
              textButton="Continue with Facebook"
            />
          </div> */}
      </div>
      <div className="col-sm-5 registerLink text-center">
        {this.state.isMainSite && 
          <React.Fragment>
            <div className="login-section mb-5">
              <p>Login to</p>
              <h3>LA PTE ACADEMIC PORTAL</h3>
              <a href="https://www.languageacademy.com.au/auth/login" className="themeBtn blueBtn">Login</a>
            </div>
            <div className="register-section">
              <h3>New User?</h3>
              <p>Register now to obtain your<br /> Free Mock Test Material</p>
              <Link to="/auth/register" className="themeBtn blueBtn">Register</Link>
            </div>
          </React.Fragment>
        }
      </div>
      <div className="col-sm-12 div20task text-center">
       {this.state.isMainSite && <React.Fragment> <h3>Get our portal for your institute</h3>
          <p>Have institute? Need portal for students?</p>
          <Button
              className="themeBtn"
              onClick={(e) => {this.showTaskModal()}}
            >Request Now</Button></React.Fragment>}
      </div>
    </div>
  </div>
  <input  type="submit" style={{display:"none"}} />
  </form>
</section>
       {/*<Container>
          <Row >
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => { this.changeInput(e)} }
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => { this.changeInput(e)} }
                        autoComplete="off"
                      />
                    </InputGroup>
                    <br />
                    <FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue=""
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          Subscribe to newsletter
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>

                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      onClick={(e) => {this.postLogIn(e)}}
                    >
                      Get Started
                    </Button>
                    <NavLink className="mt-5" to='/auth/forgot'>Forgot Password?</NavLink>
                  </CardFooter>

                </Card>
              </Form>
            </Col>
          </Row>
        </Container>*/}
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
          }}
        />
      </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
