import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import API from "api.js";
import { store } from "store/index";

const BookFreeClass = ({ isSubscribed, showDiscountPopup }) => {
  const [btcModal, setBtcModal] = useState(false);
  const [btcSuccessBag, setBtcSuccessBag] = useState("");
  const [btcErrorBag, setBtcErrorBag] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    btcName: "",
    btcPhone: "",
    btcDesiredScore: "",
    btcEmail: store.getState()?.user.email,
    btcPreferredMode: "",
  });

  const showbtcModal = () => {
    setBtcModal(true);
  };

  const closebtcModal = () => {
    setBtcModal(false);
    setBtcSuccessBag("");
    setBtcErrorBag("");
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const booktrialclassbtn = async (e) => {
    e.preventDefault();
    const { btcName, btcPhone, btcEmail, btcPreferredMode, btcDesiredScore } =
      formData;
    if (
      btcName == "" ||
      btcPhone == "" ||
      btcEmail == "" ||
      btcPreferredMode == "" ||
      !btcDesiredScore
    ) {
      setBtcErrorBag("Please fill all fields!");
    } else {
      setIsDisabled(true);
      setBtcErrorBag("");

      var bodyFormData = new FormData();
      bodyFormData.set("name", btcName);
      bodyFormData.set("phone", btcPhone);
      bodyFormData.set("email", btcEmail);
      bodyFormData.set("preferred_mode", btcPreferredMode);
      bodyFormData.set("desired_score", btcDesiredScore);

      let res = null;
      try {
        res = await API({
          method: "POST",
          url: "/btc-submit-data",
          data: bodyFormData,
        });
      } catch (error) {}

      if (res.status === 200) {
        setBtcSuccessBag("Query Submitted Successfully!");
        setIsDisabled(false);
        setFormData({
          btcName: "",
          btcPhone: "",
          btcDesiredScore: "",
          btcEmail: "",
          btcPreferredMode: "",
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        setBtcErrorBag("Unable to submit query!");
        setIsDisabled(false);
      }
    }
  };

  return (
    <>
      {!isSubscribed && showDiscountPopup && (
        <div className="alert alert-warning row ml-0 mr-0">
          <div className="col-sm-10">
            <b>Suggestion :</b> More than 107 students got their desired score
            with LA in the last month. Book your FREE Trial Class NOW.
          </div>
          <div className="col-sm-2 text-right">
            <Button
              color="info"
              className="bottom-btn"
              onClick={showbtcModal}
              disabled={false}
              size="sm"
            >
              Book Now!
            </Button>
          </div>
        </div>
      )}

      <Modal className="btcpopup" centered={true} isOpen={btcModal} size="lg">
        <ModalBody>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="12" md="12">
                <Form action="" className="form" method="">
                  <Card className="card-btc">
                    <CardHeader>
                      {btcSuccessBag && (
                        <Alert color="success">{btcSuccessBag}</Alert>
                      )}
                      {btcErrorBag && (
                        <Alert color="warning">{btcErrorBag}</Alert>
                      )}
                      <CardHeader>
                        <h3 className="header text-center">Book Trial Class</h3>
                        <hr></hr>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Name"
                          type="text"
                          name="btcName"
                          value={formData.btcName}
                          onChange={(e) => inputChangeHandler(e)}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Phone"
                          type="number"
                          name="btcPhone"
                          value={formData.btcPhone}
                          onChange={inputChangeHandler}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="text"
                          name="btcEmail"
                          value={formData.btcEmail}
                          onChange={inputChangeHandler}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-tap-01" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <select
                          onChange={inputChangeHandler}
                          className="form-control"
                          name="btcPreferredMode"
                        >
                          <option value="">Select Preferred Mode</option>
                          <option value="online">Online</option>
                          <option value="on-campus">On Campus</option>
                        </select>
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-satisfied" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Desired Score"
                          type="text"
                          name="btcDesiredScore"
                          value={formData.btcDesiredScore}
                          onChange={inputChangeHandler}
                          autoComplete="off"
                        />
                      </InputGroup>
                      <br />
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-round mb-3"
                        color="warning"
                        disabled={isDisabled}
                        onClick={booktrialclassbtn}
                      >
                        Submit
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="modalCloseBtn"
            onClick={closebtcModal}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BookFreeClass;
