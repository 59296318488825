import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const Image = ({ path, alt, classes }) => {
  const [showSkeleton, setShowSkeleton] = useState(true);

  const actualImg = (
    <img
      src={path}
      className={classes}
      alt={alt}
      style={{ display: showSkeleton ? "none" : "inherit" }}
      onLoad={() => {
        setShowSkeleton(false);
      }}
    />
  );

  return (
    <>
      {actualImg}
      {showSkeleton && (
        <Skeleton
          count={1}
          height={150}
          style={{ borderRadius: 0, lineHeight: "normal", width: "100%" }}
        />
      )}
    </>
  );
};

export default Image;
