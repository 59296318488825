import React from "react";
import Unfinished from "./Unfinished";
import NotFinished from "./NotFinished";
import ChromeCheck from "views/ChromeCheckGlobal";

const MockStatusCheck = ({ mockData, id, allOk }) => {
  return (
    <>
      {!mockData.alreadyCompleted && mockData.redirectToPending && (
        <Unfinished id={id} />
      )}{" "}
      {/* change the route 👆 */}
      {!mockData.alreadyCompleted && mockData.redirectToBegin && (
        <NotFinished id={id} />
      )}
      {!allOk && <ChromeCheck />}
      {!mockData.connected && (
        <div className="connectivity-container">
          <div className="bg-danger text-light not-connected text-center p-1">
            You are not connected to internet. We are saving your responses with
            us and will be submitted once conncetion is restored.
          </div>
        </div>
      )}
    </>
  );
};

export default MockStatusCheck;
