import { setAlert } from "action";
import React from "react";
import { useDispatch } from "react-redux";
import Section from "./Section";
import API from "api";

const Questions = ({
  mockData,
  setMockData,
  soundArr,
  routeState,
  timerRef,
}) => {
  const dispatch = useDispatch();

  const nextQuestion = async (obj) => {
    let obj2 = {
      data: obj.data,
      text_answer: obj.answer,
      length: obj.length,
      correct: obj.correct,
      second: obj.time,
      lang: obj.lang,
      html: obj.html,
      audio_text: obj.audio_text,
      duration: obj.duration,
      strategy: mockData.result.strategy,
    };
    let obj1 = finalObjectCreation(obj2);
    finalSubmit(obj1);

    if (mockData.nextType === 1 || mockData.nextType === 2) {
      submitFailed();

      setMockData((prevState) => {
        return {
          ...prevState,
          stopRecording: false,
          modal: false,
          dashboardFlag: true,
        };
      });
      dispatch(
        setAlert({
          flag: true,
          type: 1,
          message: "Mock submitted successfully",
        })
      );
    } else {
      let soundStat = true;
      if (
        soundArr.includes(
          mockData.questionData[mockData.currentQuestion + 1].subcategory_id
        )
      ) {
        soundStat = false;
      }
      let currentStrategy = mockData.result.strategy;
      let questionTimeData = { isQuestionTime: false };
      let { time } = timerRef.current.timerData();
      let { second: timeToDeduct } = obj1;

      if (timeToDeduct) {
        time = time - timeToDeduct;
        if (time < 1) {
          time = 1;
        }
      }

      setMockData((prevState) => {
        const type =
          prevState.questionData[prevState.currentQuestion + 1].subcategory_id;

        let { time_for_each_question: questionTime } =
          prevState.categoriesData.find(
            (value) => value.question_sub_category_id === type
          );

        if (questionTime) {
          if (time < questionTime * 60) {
            questionTime = time / 60;
          }

          questionTimeData = {
            isQuestionTime: true,
            questionTime,
          };
        }

        return {
          ...prevState,
          soundStat,
          stopRecording: false,
          result: {
            strategy: currentStrategy,
          },
          currentQuestion: prevState.currentQuestion + 1,
          type,
          ...questionTimeData,
          modal: false,
          secondCount: time,
        };
      });
    }
  };

  const submitFailed = () => {
    let data = [];
    let formData = new FormData();
    mockData.failed.forEach((value, index) => {
      let object = {};
      value.forEach((value, key) => {
        if (key === "file[]") {
          formData.append("files[]", value);
        } else {
          object[key] = value;
          formData.append(`data[${index}][${key}]`, value);
        }
      });
      data.push(object);
    });
    setTimeout(() => {
      API({
        method: "POST",
        url: "submitFailed/mock",
        data: formData,
      })
        .then(() => {})
        .catch(() => {});
    }, 2000);
  };

  const finalObjectCreation = (obj1 = false) => {
    let qdata = mockData.questionData[mockData.currentQuestion];
    let response = true;
    if (Object.keys(mockData.result).length === 0) {
      response = true;
    }
    let obj = {};
    if (obj1) {
      obj = {
        ...obj1,
        type: qdata.subcategory_id,
        id: qdata.id,
        response: response,
        answer: qdata.question,
        script: qdata.audio_script,
        q_ans: qdata.answer,
      };
    } else {
      obj = {
        ...mockData.result,
        type: qdata.subcategory_id,
        id: qdata.id,
        response: response,
        answer: qdata.question,
        script: qdata.audio_script,
        q_ans: qdata.answer,
      };
    }
    return obj;
  };

  const finalSubmit = (obj1) => {
    let { time: secondCount, qTime } = timerRef.current.timerData();
    let { questionData, currentQuestion, pending, complete } = mockData;
    let curr = questionData[currentQuestion];
    let obj = [];
    let time = secondCount;
    obj.push(obj1);
    let question = {}; // store questions which are not saved in database.

    let mockId = routeState.id;
    let bodyFormData = new FormData();
    if (routeState.pending == 1) {
      bodyFormData.append("result_id", mockId);
    }

    bodyFormData.append("q_count", questionData.length);
    bodyFormData.append("mock_id", mockId);
    bodyFormData.append("q_time", qTime);
    bodyFormData.append("pending", pending);
    bodyFormData.append("curr_q", 0);
    bodyFormData.append("complete", complete);
    bodyFormData.append("question_number", curr.question_number);

    obj.map((v, k) => {
      if (v.second) {
        time = secondCount - v.second;
        if (time < 1) {
          time = 1;
        }
      } else {
        time = secondCount;
      }
      bodyFormData.append("time", time);
      bodyFormData.append("response[]", v.response);
      bodyFormData.append("device", "website");
      if ([1, 2, 3, 4, 5].includes(v.type)) {
        bodyFormData.append("answer[]", v.ans);
        if (v.data === "" || v.data === undefined) {
          bodyFormData.append("file[]", new Blob(["hello worllllllllllllld"]));
        } else {
          bodyFormData.append("file[]", v.data);
        }

        // Add strategy only for read-aloud.
        if (parseInt(v.type) === 1) {
          bodyFormData.set("strategy", v.strategy);
        }
      } else if ([6, 7].includes(v.type)) {
        bodyFormData.append("answer[]", v.q_ans);
        bodyFormData.append("file[]", new Blob(["hello worlppppppppppppd"]));
      } else if ([8, 9, 10, 11, 12].includes(v.type)) {
        bodyFormData.append("answer[]", v.correct);
        bodyFormData.append("file[]", new Blob(["hello worlppppppppppppd"]));
      } else if ([13, 14, 15, 17, 19, 20, 18, 16].includes(v.type)) {
        bodyFormData.append("answer[]", v.correct);
        bodyFormData.append("file[]", new Blob(["hello worlppppppppppppd"]));
      }
      bodyFormData.append("selected[]", v.text_answer);
      bodyFormData.append("text[]", v.answer);
      bodyFormData.append("type[]", v.type);
      bodyFormData.append("id[]", v.id);
      bodyFormData.append("duration[]", v.duration);
      bodyFormData.append("script[]", v.script);
      bodyFormData.append("q_ans[]", v.q_ans);
      bodyFormData.append("audio_text[]", v.audio_text);
      bodyFormData.append("html[]", v.html);
      bodyFormData.append("text_answer[]", v.text_answer);
      bodyFormData.append("length[]", v.length);
      bodyFormData.append("lang[]", v.lang);
      bodyFormData.append("correct[]", v.correct);
      bodyFormData.append("ans[]", v.q_ans);

      question = missingQuestionData(question, v);
    });

    API({
      method: "POST",
      url: "submit/mock",
      data: bodyFormData,
    })
      .then((data) => {
        let filteredAry = mockData.ids.filter(function (e) {
          return e.id !== obj[0].id;
        });

        setMockData((prevState) => {
          return {
            ...prevState,
            // secondCount: time,
            qTime: 0,
            ids: filteredAry,
          };
        });

        if (!data.status === 200 && !data.data.status === 200) {
          setMockData((prevState) => {
            return { ...prevState, submitProcess: false };
          });
          dispatch(
            setAlert({ flag: true, type: 2, message: "Something went wrong." })
          );
        }
      })
      .catch(() => {
        let arr = [];
        arr = mockData.failed;
        arr.push(bodyFormData);
        setMockData((prevState) => {
          return { ...prevState, failed: arr };
        });
      });
  };

  const missingQuestionData = (question, response) => {
    question.text = response.answer;
    question.selected = response.text_answer;
    question.text_answer = response.text_answer;
    question.type = response.type;
    question.lang = response.lang;
    question.script = response.script;
    question.html = response.html;
    question.correct = response.correct;
    question.length = response.length;
    question.id = response.id;
    question.audio_text = response.audio_text;
    question.q_ans = response.q_ans;
    question.duration = response.duration;
    question.ans = response.q_ans;
    question.device = "website";
    question.response = response.response;

    if ([1, 2, 3, 4, 5].includes(response.type)) {
      question.answer = response.ans;
      question.file =
        response.data !== undefined || response.data !== ""
          ? response.data
          : new Blob(["hello worllllllllllllld"]);
    } else if ([6, 7].includes(response.type)) {
      question.answer = response.q_ans;
      question.file = new Blob(["hello worlppppppppppppd"]);
    } else if ([8, 9, 10, 11, 12].includes(response.type)) {
      question.answer = response.correct;
      question.file = new Blob(["hello worlppppppppppppd"]);
    } else if ([13, 14, 15, 17, 19, 20, 18, 16].includes(response.type)) {
      question.answer = response.correct;
      question.file = new Blob(["hello worlppppppppppppd"]);
    }
    return question;
  };

  const resultStatus = () => {
    setMockData((prevState) => {
      return { ...prevState, isResult: true };
    });
  };

  const soundChecked = () => {
    setMockData((prevState) => {
      return { ...prevState, soundStat: true };
    });
  };

  const submitResponse = (data, f = false) => {
    let obj = {
      data: data.data,
      textAnswer: data.answer,
      length: data.length,
      correct: data.correct,
    };
    if (f) {
    } else {
      setMockData((prevState) => {
        return { ...prevState, result: obj };
      });
    }
  };

  const setStrategy = (st) => {
    const r = { ...mockData.result };
    r.strategy = st;
    setMockData((prevState) => {
      return {
        ...prevState,
        result: r,
      };
    });
  };

  const nextQuestionHandler = () => {    
    // if mock is timed out then do not process further
    if (mockData.timeOut) {
      return;
    }

    setMockData((prevState) => {
      return { ...prevState, stopRecording: true };
    });
  };

  const data = {
    submit: submitResponse,
    showResult: resultStatus,
    data: mockData.questionData[mockData.currentQuestion],
    resultMode: false,
    testMode: true,
    soundChecked: soundChecked,
    stopRecording: mockData.stopRecording,
    nextQuestion: nextQuestion,
    strategy: mockData.result.strategy,
    setStrategy: setStrategy,
    isQuestionTime: mockData.isQuestionTime,
    questionTime: mockData.questionTime,
    nextQuestionHandler: nextQuestionHandler,
  };

  return (
    <>
      {/* {mockData.timeOut && <div className="m-3">Test stopped due to timeout.</div>} */}
      <Section mockData={mockData} data={data} />
    </>
  );
};

export default Questions;
