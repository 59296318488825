import React,{Component} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  ModalHeader,
  Input,
  Modal,
  Tooltip,
  ListGroupItem,
  ListGroup,
  ModalBody,ModalFooter
} from "reactstrap";
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import { Redirect } from "react-router-dom";
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import ReadAloud from "components/Question/Speaking/ReadAloud.jsx";
import DescribeImage from "components/Question/Speaking/DescribeImage.jsx";
import RepeatSentece from "components/Question/Speaking/RepeatSentece.jsx";
import RetellLec from "components/Question/Speaking/RetellLec.jsx";
import ShortQue from "components/Question/Speaking/ShortQue.jsx";
import Summary from "components/Question/Writting/Summary.jsx";
import Essay from "components/Question/Writting/Essay.jsx";
import SingleAnswer from "components/Question/Reading/SingleAnswer.jsx";
import MultipleAnswer from "components/Question/Reading/MultipleAnswer.jsx";
import Reorder from "components/Question/Reading/Reorder1.jsx";
import ReadingBlank from "components/Question/Reading/ReadingBlank.jsx";
import WrittingBlank from "components/Question/Reading/WrittingBlank.jsx";
import Summer from "components/Question/Listening/Summer.jsx";
import McqSingle from "components/Question/Listening/McqSingle.jsx";
import McqMulti from "components/Question/Listening/McqMulti.jsx";
import ListeningBlank from "components/Question/Listening/ListeningBlank.jsx";
import MissingWord from "components/Question/Listening/MissingWord.jsx";
import CorrectSummary from "components/Question/Listening/CorrectSummary.jsx";
import Dictation from "components/Question/Listening/Dictation.jsx";
import Highlight from "components/Question/Listening/Highlight.jsx";
import ReactHtmlParser from 'react-html-parser';
import "components/custom.css";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import API, {base,s3base} from "api.js";
import Unauthorized from "components/Exceptions/Unauthorized";
import TooltipItem from "components/ToolTip/ToolTipItem.jsx";


class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData:false,
      currentQuestion:0,
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      type:'',
      result:{},
      second:0,
      finalResult:[],
      lastFlag:true,
      resultData:'',
      modal:false,
      pending: false,
      id:0,
      scoreData:[],
      mock_title:'',
      reportModal:false,
      comment:"",
      reportText:"",
      reportErr:"",
      unauthorized: false,
      isLoaded: false,
    };
  }

  componentWillUnmount() {
    document.body.classList.toggle("sidebar-mini");
    //document.querySelector('#minimizeSidebar').style.display = "block"
  }
  reportModalfunc = () =>{
    this.setState({reportModal:!this.state.reportModal});
  }

  changeReportText = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }

  componentDidMount() {
    document.body.classList.toggle("sidebar-mini");
    //document.querySelector('#minimizeSidebar').style.display = "none"
    API({
        method: 'GET',
        url: 'mock/resultDetail/'+this.props.match.params.id
      }).then(data => {
        this.setState({ isLoaded: true });
        console.log(data.data.data);
        if(
          typeof data.data.message !== 'undefined' && 
          typeof data.data.message !== undefined && 
          data.data.message === 'unauthorized access'
        ) {
          this.setState({unauthorized: true});
        }
        //if (data.data.data.question.length != data.data.data.question_count ) {
        //  this.setState({pending:false})
        //}else{
          this.setState({
            mock_title: data.data.data.mock.title,
            questionData: data.data.data.question,
            type: data.data.data.question[0].question.subcategory_id
          }, () => {
            this.displayResult();
          });        
       // }
        // var intervalId = setInterval(this.timer, 1000);        
    }).catch(()=>{
      
    });
      navigator.getUserMedia({ audio: true },
        () => {
          this.setState({ isBlocked: false });
        },
        () => {
          this.setState({ isBlocked: true })
        },
      );
  }

  timer = () => {
    this.setState({second:this.state.second+1});
  }

    submitReport = () => {

    if(this.state.reportText === ""){
      this.setState({reportErr:"Message cant't be empty"});
      store.dispatch(setAlert({'flag':true,'type':2,'message':"Message cant't be empty"}));
      return ""
    }
    this.setState({reportErr:""});

    var bodyFormData = new FormData();
    bodyFormData.set('text', this.state.reportText);
    bodyFormData.set('question_id', this.state.questionData[this.state.currentQuestion].question_id);
    API({
      method: 'POST',
      data: bodyFormData,
      url: 'report/question',
    }).then(data => {
      if (data.status === 200) {
        this.setState({'reportText':'',reportModal:false});
        store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.message}));  
      }
      
    }).catch(()=>{
      
    });  
  }

  displayResult = () => {
    let q_data = this.state.questionData[this.state.currentQuestion];
    let selected_arr = [];
    let correct_arr = [];
    console.error(q_data)
    switch(q_data.type) {
      case 11:
      if (q_data.answer) {
        q_data.answer.split(',').map((v,k)=>{ 
          if(v.trim() === q_data.correct.split(',')[k].trim()){
            document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="right-blank">'+q_data.answer.split(',')[k]+'&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';
          }else{
            if (document.querySelectorAll('.selected-option').length > k) {
              document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="wrong-blank">'+q_data.answer.split(',')[k]+'&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';  
            }
            
          }
        });
      }else{
        q_data.correct.split(',').map((v,k)=>{ 
          document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>'
        })
      }
        
        
        break;
      case 10:
      let elemArr = document.querySelectorAll('.list-group-item1');
      for (var i = 0; i < elemArr.length; i++) {
        elemArr[i].classList.add('cross');
      }

      if (q_data.answer) {
        let correct = q_data.correct.split(',');
        let answer = q_data.answer.split(',');
        for (var i = 0; i < answer.length-1; i++) {
          for (var j = 0; j < correct.length-1; j++) {
            if (correct.length > i+1) {
              if (answer[i]+'-'+answer[i+1] == correct[j]+'-'+correct[j+1] ) {
                elemArr[i].classList.remove('cross');
                
              }
            }
          }
        }
      }

        
        
        
        break;
      case 16:
        if (q_data.answer === "-") {
          let allInput = document.querySelectorAll('.selected-option');
          for (var i = 0; i < allInput.length; i++) {
            allInput[i].innerHTML = '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
          }
        }else{
          q_data.answer.split(',').map((v,k)=>{ 
            if(v.trim() === q_data.correct.split(',')[k].trim()){
              document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="right-blank">'+q_data.answer.split(',')[k]+'&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';  
            }else{
              if (document.querySelectorAll('.selected-option').length > k) {
                document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="wrong-blank">'+q_data.answer.split(',')[k]+'&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>'           
              }
              
    
            }
          });  
        }
        
        
        break;
      case 12:
      if (q_data.answer) {
        q_data.correct.split(',').map((v,k)=>{
          document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';  
        })
        q_data.answer.split(',').map((v,k)=>{ 
          if(v.trim() === q_data.correct.split(',')[k].trim()){
            document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="right-blank">'+q_data.answer.split(',')[k]+'&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';
          }else{
            if (document.querySelectorAll('.selected-option').length > k) {
              document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="wrong-blank">'+q_data.answer.split(',')[k]+'&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
            }
          }
        });
      }else{
        q_data.correct.split(',').map((v,k)=>{
          document.querySelectorAll('.selected-option')[k].innerHTML =  '<span class="wrong-blank">&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';  
        })
      }
        
        
        break;
      case 15:
        this.changeColorReset();
        //document.querySelectorAll('input').parentElement.parentElement.parentElement.style="background:white";    
        
        correct_arr = [];
        if (q_data.correct) {
          correct_arr = q_data.correct.split(',').map(item => item.trim());
        }
        if (q_data.answer) {
          
          selected_arr = q_data.answer.split(',').map(item => item.trim());
          selected_arr.map((k)=> {
            if (correct_arr.includes(k)) {
              document.querySelector('input[value="'+k+'"]').parentElement.parentElement.parentElement.style="background:green";         
            }else{
              document.querySelector('input[value="'+k+'"]').parentElement.parentElement.parentElement.style="background:red";
            }

          });
        }
        let result = correct_arr.filter(x => !selected_arr.includes(x));
        result.map((k)=>{
          document.querySelector('input[value="'+k+'"]').parentElement.parentElement.parentElement.style="background:blue";
        });
        if(q_data.correct){
            //document.querySelector('input[value="'+q_data.correct+'"]').parentElement.parentElement.parentElement.style="background:blue";         
          }else{
            let correct = q_data.question.option.filter((p) => {if(p && p.correct === 1) return p; } );
            correct.map((p)=>{
              document.querySelector('input[value="'+p.id+'"]').parentElement.parentElement.parentElement.style="background:blue";
            });
              
          }

        break;
      case 18:
        this.changeColorReset();
        if (q_data.answer != null && q_data.correct.trim() === q_data.answer.trim()) {
            document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:green";
        }else{
          if (q_data.answer != null) {
            document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:red";
          }
          document.querySelector('input[value="'+q_data.correct+'"]').parentElement.parentElement.parentElement.style="background:blue";
        }
        break;
      case 17:
        this.changeColorReset();
        if (q_data.answer != null && q_data.correct.trim() === q_data.answer.trim()) {
          document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:green";
        }else{
          if (q_data.answer != null) {
            document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:red";
          }
          document.querySelector('input[value="'+q_data.correct+'"]').parentElement.parentElement.parentElement.style="background:blue";
        }
        break;
      case 14:
        this.changeColorReset();
        if (q_data.answer  && q_data.correct.trim() === q_data.answer.trim()) {
          document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:green";       
        }else{
          if (q_data.answer) {
            document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:red";
          }         
          if(q_data.correct){
            document.querySelector('input[value="'+q_data.correct+'"]').parentElement.parentElement.parentElement.style="background:blue";         
          }else{
            let correct = q_data.question.option.filter((p) => {if(p && p.correct === 1) return p; } );
            document.querySelector('input[value="'+correct[0].id+'"]').parentElement.parentElement.parentElement.style="background:blue";  
          }
        }
        break;
      case 8:
        this.changeColorReset();
        if (q_data.answer && q_data.answer != null && q_data.correct.trim() === q_data.answer.trim()) {
          document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:green";
        }else{
          if (q_data.answer && q_data.answer != null) {
            document.querySelector('input[value="'+q_data.answer+'"]').parentElement.parentElement.parentElement.style="background:red";           
          }
          if(q_data.correct){
            document.querySelector('input[value="'+q_data.correct+'"]').parentElement.parentElement.parentElement.style="background:blue";         
          }else{
            let correct = q_data.question.option.filter((p) => {if(p && p.correct === 1) return p; } );
            document.querySelector('input[value="'+correct[0].id+'"]').parentElement.parentElement.parentElement.style="background:blue";  
          }
          
        }
        break;
      case 9:
        this.changeColorReset();
        correct_arr = [];
        if (q_data.correct) {
         correct_arr = q_data.correct.split(',').map(item => item.trim());
        }
        if (q_data.answer) {
            selected_arr = q_data.answer.split(',').map(item => item.trim());
            selected_arr.map((k)=> {
              console.error(k)
              if (correct_arr.includes(k)) {
                if (document.querySelector('input[value="'+k+'"]')) {
                    document.querySelector('input[value="'+k+'"]').parentElement.parentElement.parentElement.style="background:green";         
                }
                         
              }else{
                if (document.querySelector('input[value="'+k+'"]')) {
                  document.querySelector('input[value="'+k+'"]').parentElement.parentElement.parentElement.style="background:red";  
                }
                
              }

            });
              
        }
        let result1 = correct_arr.filter(x => !selected_arr.includes(x));
        result1.map((k)=>{
          if (document.querySelector('input[value="'+k+'"]')) {
            document.querySelector('input[value="'+k+'"]').parentElement.parentElement.parentElement.style="background:blue";         
          }
          
        });
        if(!q_data.correct){
            let correct = q_data.question.option.filter((p) => {if(p && p.correct === 1) return p; } );
            correct.map((p)=>{
              document.querySelector('input[value="'+p.id+'"]').parentElement.parentElement.parentElement.style="background:blue";
            });
              
          }
        
        break;
      case 19:
        console.error(this.state.questionData[this.state.currentQuestion].html )
        if (this.state.questionData.length > 0) {
          document.querySelector('.result-correct').innerHTML = this.state.questionData[this.state.currentQuestion].html;
        }
        //document.querySelector('.result-correct').innerHTML = "";//this.state.questionData[this.state.currentQuestion].html;      
        
      let elem = document.querySelectorAll('.result-correct span');
      for (var i = 0; i < elem.length; i++) {
          let hig = document.querySelectorAll('.result-correct span')[i].classList.value;
          let higText = document.querySelectorAll('.result-correct span')[i].textContent;
          if (hig == "correct hidd") {
            document.querySelectorAll('.result-correct span')[i-1].style = "background:blue;color:white";     
          }
      }
      for (var i = 0; i < elem.length; i++) {
          let hig = document.querySelectorAll('.result-correct span')[i].classList.value;
          let higText = document.querySelectorAll('.result-correct span')[i].textContent;
          let corr = "";
          if (elem.length > (i+1)) {
            corr = document.querySelectorAll('.result-correct span')[i+1].classList.value;    
          }
        if (hig == "highlight1") {
            if (corr == "correct hidd") {
              document.querySelectorAll('.result-correct span')[i].innerHTML = higText+'&nbsp; <i class="fa fa-check" style="font-size:16px;"></i></span>';     
              document.querySelectorAll('.result-correct span')[i].style = "background:green";     
            }else{
            document.querySelectorAll('.result-correct span')[i].innerHTML = higText+'&nbsp; <i class="fa fa-times" style="font-size:16px;"></i></span>';
            document.querySelectorAll('.result-correct span')[i].style = "background:red";
          }
          
        }

      }
        
        break;
      default:
        
    }

  };

  changeColorReset = () => {
    let allInput = document.querySelectorAll('input');
    for (var i = 0; i < allInput.length; i++) {
      allInput[i].parentElement.parentElement.parentElement.style="background:white;color:black !important;";
    }
  };
  showQuestion(type) {
    return <Redirect to={"/practice/"+type} />;
  }
  submitResponse = (data) => {
      
  }
  showScore = (e,id) =>{
    this.setState({modal:!this.state.modal,id:id});
    
  }
  toggle = () =>{
    this.setState({modal:!this.state.modal});
  }
  showHistoryFunc = () =>{
    let id = this.state.questionData[this.state.currentQuestion].id;
    let arr = this.state.questionData.map((v) => { if (id === v.id) {return v.score}  });
    let arr1 =arr.filter((va)=> {if(typeof va === "object"){ return va;}})[0]
    let a =  arr1.map( (v) =>{ return(`<tr><td>${this.remarks(v.type)}</td><td>${v.score}/${v.from}</td><td>${v.remarks}</td></tr>` )} );
    if ([6,7,13].includes(this.state.questionData[this.state.currentQuestion].type)) {
      let t_from = 0;
      let t_score = 0;
      for (var i = 0; i <= arr1.length-1; i++) {
        t_from += arr1[i].from;
        t_score += arr1[i].score;
      }
      a.push('<tr><td>Total</td><td colspan="2">'+t_score+'/'+t_from+'</td></tr>')
    }
    console.log(a);
    return a;
  }
  remarks = (type) => {
    let str="";
    switch(type) {
      case 0:
        str ="Content";
        break;
      case 1:
        str ="Fluency";
        break;
      case 2:
        str ="Pronunciation";
        break;
      case 3:
        str ="Score";
        break;
      case 4:
        str ="Grammar";
        break;
      case 5:
        str ="Form";
        break;
      case 6:
        str ="Vocabulary";
        break;
      case 7:
        str ="Spelling";
        break;
      case 8:
        str = this.state.type == 7 ? "Organization" : "Structure";
        break;
      case 9:
        str = this.state.type == 7 ? "Email Conventions" : "Linguistic range";
        break;
      default:
        str = "";
    }
    return str;    
  }
  resultStatus = () => {
    this.setState({is_result:true});

  }

  soundChecked = () => {}
  stopRecording = () => {}
  nextQuestion = ()=>{}

  changeQuestion = (e,k) => {
    let question = this.state.questionData[k];
    this.setState({result:{},currentQuestion:k,type:this.state.questionData[k].question.subcategory_id},()=>{
      this.displayResult();

    });

    this.forceUpdate();
  }



  nextButton = () => {

    let question = this.state.questionData[this.state.currentQuestion+1];
    this.setState({result:{},currentQuestion:this.state.currentQuestion + 1,type:this.state.questionData[this.state.currentQuestion+1].question.subcategory_id},()=>{
      this.displayResult();
    });
    this.forceUpdate();
  }
  prevButton = () => {
    this.setState({result:{},currentQuestion:this.state.currentQuestion - 1,type:this.state.questionData[this.state.currentQuestion-1].question.subcategory_id},() => {
      this.displayResult();
    });
    this.forceUpdate();
    
  }
  finalSubmit = () =>{
    
  }

  renderResponse = (html) => {

    console.log(html.charAt(0));
    // return "true";
    // const items = typeof html === 'string' ? JSON.parse(html) : html;
    // const items = html.charAt(0) === '<' ? html : JSON.parse(html);
    let items = [];
    if(html.charAt(0) === '<'){
      return ReactHtmlParser(html)
    } else {
      items = JSON.parse(html);
    }

    return (
      <>
        {
          items.map((item, i) => {
            if (this.state.questionData[0].subcategory_id === 5) {
              return (
                <>
                  <span>Your Response: </span><span className={item.class} key={i}>{item.word !== "&nbsp;" ? item.word : " "}</span>
                </>
              );
            } else {
              return <TooltipItem key={i} item={item} id={i} />;
            }
          })
        }
      </>
    );
  }


  render() {
    let que;
    let val;
    let topbar = false;
    let props ={};
    if (this.state.questionData) {
      props = {
        submit: this.submitResponse,
        showResult: this.resultStatus,
        data: this.state.questionData[this.state.currentQuestion].question,
        answer: true,
        resultMode: true,
        testMode: false,
        soundChecked: this.soundChecked,
        stopRecording: this.state.stopRecording,
        nextQuestion: this.state.nextQuestion
      };  
      val = this.state.questionData[this.state.currentQuestion];
    }
    let title = "";
    let ques_title = "";
    if (this.state.questionData.length >0) {
      ques_title = this.state.questionData[this.state.currentQuestion].q_title;
    }

    switch(this.state.type) {
      case 1:
        que = <ReadAloud {...props} />;
        title = "Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.";
        break;
      case 2:
        que = <RepeatSentece {...props} />;
        title = "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.";
        break;
      case 3:
        que = <DescribeImage {...props} />;
        title = "Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.";
        break;
      case 4:
        que = <RetellLec {...props} />;
        title = "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.";
        break;
      case 5:
        que = <ShortQue {...props} />;
        title = "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.";
        break;
      case 6:
        que = <Summary {...props} />;
        title = "Read the passage and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. your response will be judge on the quality of your writing and on how well your response presents the key points in the passage.";
        break;
      case 7:
        que = <Essay {...props} />;
        title = "You will have 20 minutes to plan, write and revise an essay about the topic below. your response will be judged on how well you develop a position, oraganize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.";
        break;
      case 8:
        topbar = true;
        que = <SingleAnswer {...props} />;
        title = "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
        break;
      case 9:
        topbar = true;
        que = <MultipleAnswer {...props} />;
        title = "Read the text and answer the question by selecting all the correct responses. More than one response is correct.";
        break;
      case 10:
        que = <Reorder {...props} />;
        title = "The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.";
        break;
      case 11:
        topbar = true;
        que = <ReadingBlank {...props} />;
        title = "In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.";
        break;
      case 12:
        topbar = true;
        que = <WrittingBlank {...props} />;
        title = "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.";
        break;
      case 13:
        que = <Summer {...props} />;
        title = "You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 20-30 words. You have 8 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.";
        break;
      case 14:
        topbar = true;
        que = <McqSingle {...props} />;
        title = "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
        break;
      case 15:
        topbar = true;
        que = <McqMulti {...props} />;
        title = "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.";
        break;
      case 16:
        topbar = true;
        que = <ListeningBlank {...props} />;
        title = "You will hear a recording. Type the missing words in each blank.";
        break;
      case 17:
        topbar = true;
        que = <CorrectSummary {...props} />;
        title = "You will hear a recording. Click on the paragraph that best relates to the recording.";
        break;
      case 18:
        topbar = true;
        que = <MissingWord {...props} />;
        title = "You will hear a recording about "+ ques_title+". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording";
        break;
      case 19:
        topbar = true;
        que = <Highlight {...props} />;
        title = "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.";
        break;
      case 20:
        que = <Dictation {...props} />;
        title = "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.";
        break;
      default:
        // code block
    }
    

    return (
      <>
        { !this.state.isLoaded ? (
          <></>
        ) : (
          <>
            { !this.state.unauthorized ? (
              <>
                {this.state.reportModal &&
                  <Modal isOpen={this.state.reportModal} size="lg">
                    <ModalBody>  
                      <ModalHeader>Report the problem in this question </ModalHeader>
                      <span className="text-danger">{this.state.reportErr}</span>
                      <Input type="textarea" value={this.state.reportText} name="reportText" onChange={(e)=>{this.changeReportText(e);}} />
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" className="modalCloseBtn" onClick={this.submitReport}>Submit</Button>
                      <Button color="secondary" className="modalCloseBtn" onClick={this.reportModalfunc}>Close</Button>
                    </ModalFooter>
                  </Modal>
                }
                
                {this.state.modal &&
                  <Modal isOpen={this.state.modal} size="lg">
                    <div className="modalHeader">
                      <h2></h2>
                    </div>
                    <ModalBody>
                      <Table>
                        <tr>
                          <th>Component</th>
                          <th>Score</th>
                          <th>Remarks</th>
                        </tr>
                        {this.showHistoryFunc().map((v) => {
                          return (ReactHtmlParser(v))
                        })}
                      </Table>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" className="modalCloseBtn" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                  </Modal>
                }
              
                <div className="content questionsArea">
                  <Row>
                    <Col xs={12}>
                      <Card>
                        {!this.state.pending &&
                          <CardBody>
                            {topbar &&
                              <div className="row infoBars">
                                <div className="col-sm-3 wrongAns">
                                  <i className="fa fa-times" aria-hidden="true"></i> <span>Your selected & wrong answer</span>
                                </div>
                                <div className="col-sm-3 correctAns">
                                  <i className="fa fa-check" aria-hidden="true"></i> <span>Your selected & correct answer</span>
                                </div>
                                <div className="col-sm-3 notSelected">
                                  <i className="fa fa-check" aria-hidden="true"></i> <span>Not selected & correct answer</span>
                                </div>
                              </div>
                            }
                      
                            <div className="row">
                              <div className="col-sm-10">
                                <h4 className="mt-0">{this.state.mock_title}</h4>
                                <h6>{title}</h6>
                                <Row className='unselectable'> 
                                  { !this.state.pending && this.state.questionData && que }
                                  {[13,19].includes(this.state.type) &&
                                    <div>
                                      <h3>Audio Script</h3>
                                      <h4>{ReactHtmlParser(val.question.audio_script)}</h4>
                                    </div>
                                  }
                                </Row>

                                <div className="result-div">
                                  {[2,4,5,15,19,14,16,18,17,13,20].includes(this.state.type) &&
                                    <div>
                                      <h3> Question Audio - </h3> <audio src={s3base+val.question.media_link} controls="controls" controlsList="nodownload" />
                                    </div>
                                  }
                                  {[6,7,13].includes(this.state.type) &&
                                    <div>
                                      <h3>Your Response</h3>
                                      <h5 className={"answer-html" + (this.state.type == 7 ? " justify-align" : "") }>{ReactHtmlParser(val.answer)}</h5>
                                      {[6,7,13].includes(this.state.type) &&
                                        <div><h3>Word count: {val.correct}</h3></div>
                                      }
                                    </div>
                                  }
                                  {[11,12,16].includes(this.state.type) &&
                                    <div>
                                      {false && <h3>Your Response</h3>}
                                      {false && val.answer.split(',').map((v,k)=>{
                                        if(k > val.correct.length && v.trim() === val.correct.split(',')[k].trim()){
                                          return ReactHtmlParser('<span>'+v+'<span><i class="fa fa-check" style="font-size:32px;color:green"></i></span></span>');
                                        }else{
                                          console.log(v)
                                          console.log(val.correct.split(',')[k])
                                          return ReactHtmlParser('<span>'+v+'<span><i class="fa fa-times" style="font-size:32px;color:red"></i></span></span>');
                                        }
                                      })}
                                    </div>
                                  }
                                  {[19].includes(this.state.type) &&
                                    <div>
                                      <h3>Your Response</h3>
                                      {<span className="result-correct"></span>}
                                    </div>
                                  }
                                  {[20].includes(this.state.type) &&
                                    <div>
                                      <h3>Correct Answer</h3>
                                      <h5 className="text-success">{ReactHtmlParser(val.question.audio_script)}</h5>
                                    </div>
                                  }
                          
                                  {[8,9,14,15,17,18,20].includes(this.state.type) &&
                                    <div>
                                      <h3>Your Response</h3>
                                      <h5>{ReactHtmlParser(val.html)}</h5>
                                    </div>
                                  }
                                  {[14,15,16,17,18,19,20].includes(this.state.type) &&
                                    <div>
                                      <h5 className="text-danger">{(val.answer)?"":"(Not Attempted)"}</h5>
                                    </div>
                                  }
                                  {[10].includes(this.state.type) &&
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h3>Correct order</h3>
                                        {que}
                                      </div>
                                      <div className="col-md-6">
                                        <h3>Your Response</h3>
                                        <div className="col-12"><h4 className="infoText"></h4></div><div className="col-12"><h5></h5></div><div className="col-12"><h3 className="question"></h3></div><div className="col-lg-12">&nbsp;</div><div className="col-lg-12"></div><div className="col-lg-12">&nbsp;</div>
                                        <ul className="connectList">
                                          {val.html.split('<br />').filter((va)=> {if(va !== ""){ return va;}}).map((v)=>{ return ReactHtmlParser('<li class="list-group-item1">'+v+'</li>')  })}
                                        </ul>
                                      </div>
                                    </div>
                                  }
                                  {[1,2].includes(this.state.type) &&
                                    <div>
                                      <h3>Your Response</h3>
                                      <h5><audio src={s3base + '/ptedata/ptemedia/' +val.file} controls="controls" /></h5>
                                      {/*<h5>{(val.html !== null) ? ReactHtmlParser(val.html) : ""}</h5>*/}
                                      <h5>{(val.html !== null) ? this.renderResponse(val.html) : ""}</h5>
                                      <div>
                                        <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW" >
                                          <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                            <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd" ></div>
                                            Good
                                          </div>
                                          <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                            <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>
                                            Average
                                          </div>
                                          <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                            <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJbad"></div>
                                            Bad pronunciation/Missed words
                                          </div>
                                          <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca"></div></div>
                                      </div>
                                    </div>
                                  }
                                  {[3,4].includes(this.state.type) &&
                                    <div>
                                      <h3>Your Response</h3>
                                      <h5><audio src={s3base + '/ptedata/ptemedia/' +val.file} controls="controls" /></h5>
                                      {/*<h5>{(val.html !== null) ? ReactHtmlParser(val.html) : ""}</h5>*/}
                                      <h5>{(val.html !== null) ? this.renderResponse(val.html) : ""}</h5>
                                      <div>
                                        <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW" >
                                          <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                            <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd" ></div>Good
                                          </div>
                                          <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                            <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>Average
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {[5].includes(this.state.type) &&
                                    <div>
                                      <h3>Your Response</h3>
                                      <h5><audio src={s3base + '/ptedata/ptemedia/' +val.file} controls="controls" /></h5>
                                      <h5>Your response: {ReactHtmlParser(val.html)}</h5>
                                      <h5>Correct answer: {val.question.answer}</h5>
                                    </div>
                                  }                
                                  {this.state.questionData &&
                                    <Button color="primary" className="viewResultbtn" onClick={(e) => this.showScore(e,this.state.questionData.id)} size="sm">View Score with Remarks</Button>
                                  }
                                </div>
                              </div>
                              <div className="col-sm-2 speakingBar p-0">
                                <h4><i className="" aria-hidden="true"></i></h4>
                                <div className="topArrow">
                                  
                                </div>
                                <div className="numbersList">
                                  {this.state.questionData && this.state.questionData.map((v,k)=>{
                                    return <span onClick={(e) => this.changeQuestion(e,k)} className={(this.state.currentQuestion == k)?'selected-index-result':''} key={k}>{(k+1)}</span>;
                                  })}
                                </div>
                                <div className="nextPrev">
                                  { (this.state.currentQuestion !== 0) && this.state.questionData.length!==0 && 
                                    <a onClick={this.prevButton} className="prev"><i className="fa fa-chevron-circle-left" aria-hidden="true"></i> Prev </a>
                                  }
                                  { (this.state.questionData.length != (this.state.currentQuestion+1)) && this.state.questionData.length!==0 &&
                                    <a onClick={this.nextButton} className="next">Next <i className="fa fa-chevron-circle-right" aria-hidden="true"></i></a>
                                  }
                                </div>
                                <div className="quesStatus">
                                  {/* <div className="atmptd">
                                    <span>Attempted</span>
                                  </div>
                                  <div className="notAatmptd">
                                    <span>Not Attempted</span>
                                  </div>
                                  <div className="currentQues">
                                    <span>Current Question</span>
                                  </div> */}
                                </div>
                                <div className="bottomBtn text-center"></div>
                              </div>
                            </div>
                          </CardBody>
                        }
                        {this.state.pending &&
                          <CardBody>
                          	<div className="scoreContent text-center">
                          		<div className="scoreLoading">
                          			<img src="https://gifimage.net/wp-content/uploads/2017/08/loading-gif-transparent-4.gif" />
                          		</div>
                          		<div className="scoreInfo">
          	                		<h4>AI Scoring Ongoing</h4>
          	                		<p>Takes around 5 - 10 minutes<br />You can check back later</p>
                          		</div>
                          	</div>
                          </CardBody>
                        }
                      </Card>
                    </Col>

                    {!this.state.pending &&
                      <Col xs={12}>
                        <div className="">
                          { (this.state.questionData.length != (this.state.currentQuestion+1)) && this.state.questionData.length!==0 &&
                            <Button className="float-right" color="primary" onClick={this.nextButton} size="lg">NEXT</Button>
                          }
                          { (this.state.currentQuestion !== 0) && this.state.questionData.length!==0 &&
                            <Button className="float-right" color="primary" onClick={this.prevButton} size="lg">PREVIOUS</Button>
                          }
                          {this.state.questionData.length !== 0 &&
                            <Button color="info" className="bottom-btn" onClick={this.reportModalfunc} disabled={false} size="sm">Report Question</Button>
                          }
                        </div>
                      </Col>
                    }
                  </Row>
                </div>
              </>) : <Unauthorized />
            }
          </>
        )}
      </>
    );
  }
}
export default Result;