import { setAlert } from "action/index";
import PlanTab from "components/Plans/PlanTabNew";
import useHttp from "hooks/useHttpRequest";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCountryForTimezone } from "countries-and-timezones";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

// added by gk. 28-02-2024
import { useLocation } from 'react-router-dom';
// -----------------------

const Plans = (props) => {
  const { response, hasError, request } = useHttp();
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();

  const [planDuration, setPlanDuration] = useState(1);
  const [country, setCountry] = useState("AU");
  const [selectedPlan, setSelectedPlan] = useState("GOLD");
  const [planPrices, setPlanPrices] = useState([]);
  const [showSidebarCollapse, setShowSidebarCollapse] = useState(false);

  // added by gk. 28-02-2024
  const useQuery= () => {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  // -----------------------

  useEffect(() => {
    const country = getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    let countryId = "AU";
    if(country && country.id) {
      countryId = country.id;
    }
    setCountry(countryId);

    if (process.env.REACT_APP_ENV === 'production') {
      request(`https://ipbackend22.languageacademy.com.au/api/v2/getPackages?countryId=${countryId}`);
    } else {
      request(`getPackages?countryId=${countryId}`);
    }
    document.getElementsByTagName("body")[0].classList.add("adminPlansPage");

    if (window.location.pathname === "/admin/plans") setShowSidebarCollapse(true);

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("adminPlansPage");
    };
  }, []);

  useEffect(() => {
    if (response) {
      const { data } = response;
      const plans = data.sort((a, b) => a.price - b.price);

      // added by gk. 28-02-2024
      let showPlans = [];
      if (queryParams.get('plan') !== null) {
        plans.map((item) => {
          if (item.is_gold === 1) {
            showPlans.push(item);
          }
        })
      } else {
        showPlans = plans;
      }
      // gk code end.----------

      setPlans(showPlans);
    }
  }, [response]);

  useEffect(() => {
    if (hasError) {
      dispatchAlert(2, "Soemthing went wrong");
      return;
    }
  }, [hasError]);

  const dispatchAlert = (type, message) => {
    dispatch(
      setAlert({
        flag: true,
        type,
        message,
      })
    );
  };

  const durationButtons = () => {
    return [1, 2, 3];
  }

  const planDurationButtons = (duration) => {
    setPlanDuration(duration);
  }

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  }


  useEffect(() => {
    if (plans.length > 0) {
      const isGold = selectedPlan === "GOLD" ? 1 : 0;
      const prices = [];
      let one_month_price = 0;
      plans.map((plan, i) => {
        if (plan.duration === 1) one_month_price = plan.price;
        if (plan.is_gold === isGold && plan.price > 0) {
          plan.special_class = plan.duration === 1 ? "skyBluePlan" : (plan.duration === 2 ? "bluePlan" : "");
          plan.base_price = plan.duration > 1 ? (one_month_price * plan.duration) : "&nbsp;";

          const listed_price = (one_month_price * plan.duration);
          plan.discount_inr = (((listed_price - plan.price)/listed_price) * 100).toFixed(0);
          
          prices.push(plan);
        }
      });
      prices.sort((a, b) => (a.duration < b.duration ? -1 : 1));
      setPlanPrices(prices);
    }
  }, [selectedPlan, plans]);


  const priceSpeciality = (duration) => {
    const specialities = ["", "", "Best Seller", "Most Savings"];
    if (duration > 0 && duration < 4) return specialities[duration];
    return "";
  }
  
  return (
    <div className="adminPlansOuter content">
      <div className="adminPlansInr row">
        <PlanTab selectPlan={selectPlan} selectedPlan={selectedPlan} />
      </div>

      <div className="shortTimePlans">
        <div className="row shortTimePlansInr">
          {planPrices.map((plan, i) => {
            return (
              <div key={i} className={`col ${plan.special_class}`}>
                <div className="shortPlanInr shadow-lg">
                  <div className="shortPlnHead">
                    <span className="planTopTag">{priceSpeciality(plan.duration)}</span>
                    <h2>
                      <span>{plan.currency !== "AUD" ? "INR" : plan.currency} ({plan.duration} month{plan.duration > 1 ? "s" : ""})</span>
                      <MonthlyPrice plan={plan} />
                      <span className="renew_note">* Auto Renews every {plan.duration} month{plan.duration > 1 ? "s" : ""}</span>
                    </h2>
                    <Link to={"/admin/sub/payment/form/" + plan.id}>Buy Now</Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};


const MonthlyPrice = ({ plan }) => {
  return (
    <>
      <span className="base_price">
        <b className="base_price_a">{ReactHtmlParser(plan.base_price)}</b> <RenderDiscount plan={plan} />
      </span>
      {plan.price}* <i className="plan_duration">({plan.duration} Month{plan.duration > 1 ? "s" : ""})</i>
    </>
  );
};


const RenderDiscount = ({ plan }) => {
  return (
    <>
      {plan.discount_inr > 0 ? (
        <b className="discount">{plan.discount_inr}% Off</b>
      ) : ""}
    </>
  );
};

export default Plans;