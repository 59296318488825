import React from "react";
import { Button } from "reactstrap";

export default function PlaybackSpeedBtns({
  playbackSpeed,
  changePlaybackSpeed,
}) {
  return (
    <>
      <Button
        color="success"
        disabled={playbackSpeed === 1}
        onClick={changePlaybackSpeed.bind(this, 1)}
        size="sm"
      >
        1x
      </Button>
      <Button
        color="success"
        disabled={playbackSpeed === 1.2}
        onClick={changePlaybackSpeed.bind(this, 1.2)}
        size="sm"
      >
        1.2x
      </Button>
      <Button
        color="success"
        disabled={playbackSpeed === 1.5}
        onClick={changePlaybackSpeed.bind(this, 1.5)}
        size="sm"
      >
        1.5x
      </Button>
      {/* <Button color="success" disabled={playbackSpeed === 2} onClick={changePlaybackSpeed.bind(this, 2)} size="sm">2x</Button> */}
    </>
  );
}
