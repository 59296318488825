import React from "react";
import Carousel from 'react-grid-carousel';

export default function Carousal(props) {
	const { items } = props;
	const slidesToShow = items.length > 3 ? 3 : items.length - 1;

	return (
		<>
			{items.length > 3 ? (
				<Carousel
					cols={3}
					rows={1}
					gap={20}
					showDots={true}
					responsiveLayout={[
						{
							breakpoint: 1200,
							cols: 3
						},
						{
							breakpoint: 990,
							cols: 2
						}
					]}
				>
					{items.map((item, i) => {
						return (
							<Carousel.Item key={i}>
								<iframe 
									width="100%"
									height="200px"
									className="tute-videos-list"
									src={item.video}
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</Carousel.Item>
						)
					})}
				</Carousel>
			) : (
				<>
					{items.map((item, i) => {
						return (
							<div className="col-md-4 my-1" key={i}>
								<iframe 
									width="100%"
									height="200px"
									className="tute-videos-list"
									src={item.video}
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</div>
						)
					})}
				</>
			)}
		</>
	);
}
