import React from "react";
import InfoCard from "./InfoCard";
import Benefits from "./Benefits";

const RightCard = ({ plan, discount, prorationSubtotal, paymentMethod }) => {
  return (
    <div className="col-xs-12 col-md-5 mr-auto pt-5 subPaymentPage">
      <div className="panel panel-default">
        <div className="panel-body mt-5">
          <InfoCard
            plan={plan}
            discount={discount}
            prorationSubtotal={prorationSubtotal}
            paymentMethod={paymentMethod}
          />
          <div className="benifitsHeading">
            <span>Benefits</span>
          </div>
          <Benefits plan={plan} paymentMethod={paymentMethod} />
        </div>
      </div>
    </div>
  );
};

export default RightCard;
