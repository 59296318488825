const { useState, useCallback } = require("react");

const useInput = (validateInputValue) => {
  const [value, setValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const isValid = validateInputValue(value);
  const hasError = isTouched && !isValid;

  const onChangeHandler = (value) => {
    setValue(value);
  };

  const onBlurHandler = (status) => {
    setIsTouched(status);
  };

  const reset = useCallback(() => {
    setValue("");
    setIsTouched(false);
  }, []);

  return {
    value,
    onChangeHandler,
    hasError,
    onBlurHandler,
    isValid,
    reset
  };
};

export default useInput;
