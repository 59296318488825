import React from "react";
import Header from "../components/Frontend/Header";
import Footer from "../components/Frontend/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
class Home extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-157984080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init("2474090032742391");
    ReactPixel.init("197333187452630");
    ReactPixel.pageView();
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Language Academy</title>
        </Helmet>
        <Header />
        <div className="innerPage">
          <section className="bannerOuter">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1> TERMS AND CONDITIONS </h1>
                  <span></span>
                  <br />
                  <a href="javascript:;" className="downArrow">
                    <img src="images/banner-arrow.png" alt="banner-arrow" />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section id="pte-modules" className="mt-5">
          <div className="container">
            <div className="row">
              <p id="terms">
                These terms and conditions, in conjunction with our privacy
                policy, collectively referred to as the "Terms," govern your
                access to and utilisation of the website,
                languageacademy.com.au, and associated mobile applications. The
                Website is operated by Language Academy Australia Pty Ltd ("Language
                Academy PTE").
              </p>
              <p>
                The terms and conditions outlined herein (the “Agreement” or
                “Terms”) constitute a legally binding agreement between the
                users of the Platform, herein defined as “you” or “the
                customers,” and Language Academy Australia Pty Ltd, referred to as the
                “Company,” “We,” “Us,” “Our,” “Service,” or “Language Academy.”
              </p>
              <p>
                By accessing or using the platform in any capacity, including
                browsing and viewing the website, you affirmatively agree to
                adhere to these Terms.
              </p>
              <p>
                We reserve the right to amend these Terms of Service at any
                time, with revisions posted on the Website. Please note that
                individual notifications will not be sent to you. Any updated
                Terms of Service shall supersede all previous versions. If you
                do not agree to the amended Terms of Service, we kindly request
                that you cease using any services provided on the Website. By
                continuing to use the Website after such amendments take effect,
                you indicate acceptance and agreement with the modified Terms.
              </p>
              <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                When conducting transactions with us on or through the Website
                or registering an account on the Website, you warrant that:
                <ul className="ml-5 mt-2">
                  <li>You are using your actual identity.</li>
                  <li>
                    The personal information you provide to us is true,
                    accurate, complete, and current.
                  </li>
                  <li>
                    You will promptly update your personal information to ensure
                    its accuracy and completeness.
                  </li>
                </ul>
              </div>
              <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                By accessing or using the Platform, browsing or viewing the
                Platform, you confirm that:
                <ul className="ml-5 mt-2">
                  <li>
                    Your use of the service is strictly for personal purposes,
                    making you solely responsible for any consequences or
                    misinterpretations of the Platform's content and access.
                  </li>
                  <li>
                    You understand that you have a limited right to use the
                    Website and no right to modify any part of it, reproduce,
                    copy, distribute, sell, resell, or access the Facility for
                    commercial purposes.
                  </li>
                  <li>
                    You will use this Website only for lawful and reasonable
                    purposes and will not engage in activities not covered by
                    these Terms. The Website and Facility are for personal,
                    non-commercial use only and should not be resold to others.
                  </li>
                  <li>
                    You will not use meta tags or hidden text using Language
                    Academy's or its affiliates' names or trademarks without
                    written consent.
                  </li>
                  <li>
                    You will not use the Website for fraudulent purposes or in
                    connection with criminal or unlawful activities.
                  </li>
                  <li>
                    You will not use the Website to send, use, or reuse any
                    material that does not belong to you, is illegal, offensive,
                    deceptive, abusive, indecent, harassing, defamatory, or
                    otherwise objectionable or unlawful.
                  </li>
                </ul>
              </div>
              <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                Language Academy may offer the option to open user accounts on
                the Website. The user account may be linked to the user's email
                address or phone number or both. By using the Website and
                registering an account with Language Academy or using the
                Website as a guest (unregistered user), you agree to the
                following:
                <ul className="ml-5 mt-2">
                  <li>
                    You are responsible for maintaining the confidentiality of
                    your account and password and for restricting access to your
                    computer to prevent unauthorised account use.
                  </li>
                  <li>
                    You accept responsibility for all activities under your
                    account or password if you use the Website through a
                    registered account.
                  </li>
                  <li>
                    You will ensure the confidentiality and security of your
                    password and inform us immediately if you suspect
                    unauthorised use or disclosure. Please ensure your provided
                    information is accurate and promptly update any changes.
                  </li>
                  <li>
                    You agree to comply with additional procedures and
                    guidelines related to Website use, as modified over time.
                    You must also adhere to all applicable laws and regulations
                    concerning Website use within your jurisdiction for each
                    transaction.
                  </li>
                </ul>
              </div>
              <p>
                Language Academy reserves the right to deny Website access,
                terminate accounts, or edit or remove content without notice if
                we reasonably believe your account is being used for
                unauthorised purposes.
              </p>
              <p>
                When you use the Website or send emails, data, information, or
                communications to us, you acknowledge and agree that you are
                communicating with us electronically. You consent to receive
                communications from us via electronic records periodically and
                as necessary. We may communicate with you via email or other
                electronic modes of communication.
              </p>
              <p>
                <b>*For any inquiries, please contact us via email: </b>
                <a
                  href="mailto:info@languageacademy.com.au"
                  style={{ color: "#162545", textDecoration: "underline" }}
                >
                  info@languageacademy.com.au
                </a>
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
