import React, { useState } from 'react';
import { Tooltip, Modal, ModalBody, ModalHeader, ModalFooter, Table, Button } from 'reactstrap';
import Speech from 'speak-tts';

export default function TooltipItem(props) {
	const { item, id } = props;
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [modal, setModal] = useState(false);
	const [showDetails, setShowDetails] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);
	const toggleModal = () => setModal(!modal);


	const showDetailsPopup = () => {
		setShowDetails(true);
	}

	const closePopup = () => {
		setShowDetails(false);
	}


	const phonemes = (details) => {
		return (
			<>
				{
					details.map((detail, i) => {
						let class_name = 'color-success';
						if (detail.decision === 'error') {
							class_name = 'color-danger';
						
						} else if (detail.decision === 'incorrect') {
							class_name = detail.nativeness_score > 30 ? 'color-warning' : 'color-danger';
						
						} else if (detail.decision === 'warning') {
							class_name = 'color-warning';
						
						} else if (detail.decision === 'correct') {
							class_name = 'color-success';
						
						}

						const text = detail.decision === 'correct' ? 'Excellent' : 'Incorrect.';
						const feedback = "feedback" in detail ? detail.feedback : null;
						return (
							<div className="row" key={i}>
								<div className="col-lg-4">/{'trans' in detail ? detail.trans : detail.ipa}/</div>
								<div className="col-lg-8">
									<span className={class_name}>{text}</span>
									{feedback !== null &&
										<>
											<br />
											<span className="word-feedback">{feedback}</span>
										</>
									}
								</div>
							</div>
						)
					})
				}
			</>
		);
	}

	const gatheredPhonemes = (details) => {
		return (
			<>
				{
					details.map((detail, i) => {
						// const class_name = detail.decision === 'error' ? 'color-danger' : (detail.decision === 'correct' ? 'color-success' : 'color-warning');
						let class_name = 'color-success';
						if (detail.decision === 'error') {
							class_name = 'color-danger';
						
						} else if (detail.decision === 'incorrect') {
							class_name = detail.nativeness_score > 30 ? 'color-warning' : 'color-danger';
						
						} else if (detail.decision === 'warning') {
							class_name = 'color-warning';
						
						} else if (detail.decision === 'correct') {
							class_name = 'color-success';
						
						}
						
						return (
							<span className={class_name} key={i}>{'trans' in detail ? detail.trans : detail.ipa}</span>
						)
					})
				}
			</>
		);
	}

	const wordPronunce = (e, word) => {
		const speech = new Speech();
		speech.speak({
			text: word
		});
	}


	return (
		<>
			<span 
				className={`${item.class} tooltip-word`}
				id={`Tooltip-${id}`}
				onClick={showDetailsPopup}
			>{item.word !== "&nbsp;" ? item.word : " "}</span>
			{item.word !== "&nbsp;" && "word_score" in item &&
				<Tooltip
					placement='top'
					isOpen={tooltipOpen}
					target={'Tooltip-' + id}
					toggle={toggle}
				>
					Word Score<br /><b className={item.class}>{parseInt(item.word_score)}</b> / 100
				</Tooltip>
			}

			{'details' in item &&
				<Modal className="word-details-modal" isOpen={showDetails} size="md" toggle={toggleModal}>
					<ModalBody className="word-details">
						<div className="popup-header text-center mb-1">
							<span className="normal-word float-left">\<i className={item.class}>{item.word.trim()}</i>\</span>
							<span className="word-ipa">\{gatheredPhonemes(item.details)}\</span>
							<span
								className="word-pronunciation float-right"
								onClick={(e) => wordPronunce(e, item.word.trim())}
							>
								<i className="fa fa-bullhorn" aria-hidden="true"></i>
							</span>
						</div>
						<div className="details-popup">
							<div className="col popup-content">
								<div className="row popup-content-heading">
									<div className="col-lg-4">Sound</div>
									<div className="col-lg-8">You said</div>
								</div>
								<div className="popup-content-data">
									{phonemes(item.details)}
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" className="modalCloseBtn" onClick={closePopup}>Close</Button>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}