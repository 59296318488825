import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

const ModalSkeleton = ({
  status,
  toggle,
  handleClick,
  btnText,
  message,
  data,
  isSmall,
}) => {
  console.log(status);
  return (
    <Modal isOpen={status} size="xl" style={isSmall ? { width: "30%" } : {}} className="questionModal">
      <ModalBody>
        <span
          onClick={() => toggle()}
          style={{
            color: "#93c13e",
            opacity: "unset",
            position: "absolute",
            top: "4%",
            right: "3%",
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </span>
        <div className="popupCntntOtr">
          <div className="popupImg">
            <img src="/images/teaching-user.png" alt="teaching-user" />
          </div>
          <div className="popupCntnt">
            <p>{message}</p>
            <Button
              color="secondary"
              onClick={handleClick}
              className="modalCloseBtn btn"
            >
              {btnText}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalSkeleton;
