import { setAlert } from "action/index";
import useHttp from "hooks/useHttpRequest";
import useInput from "hooks/useInput";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "reactstrap";

const ContactUsPremium = () => {
  const dispatch = useDispatch();
  const {
    value: subject,
    hasError: subjectError,
    onChangeHandler: subjectChangeFn,
    onBlurHandler: subjectBlurFn,
    isValid: isSubjectValid,
    reset: resetSubject,
  } = useInput((value) => !!value);

  const {
    value: message,
    hasError: messageError,
    onChangeHandler: messageChangeFn,
    onBlurHandler: messageBlurFn,
    isValid: isMessageValid,
    reset: resetMessage,
  } = useInput((value) => !!value);

  const { response, error, hasError: responseHasError, request } = useHttp();

  useEffect(() => {
    if (responseHasError) {
      dispatchAlert(2, "Soemthing went wrong");
      return;
    }
    if (response) {
      dispatchAlert(1, response.message);
      resetSubject();
      resetMessage();
    }
  }, [response, responseHasError, resetMessage, resetSubject]);

  const dispatchAlert = (type, message) => {
    dispatch(
      setAlert({
        flag: true,
        type,
        message,
      })
    );
  };

  const submit = async (e) => {
    e.preventDefault();

    if (isSubjectValid && isMessageValid) {
      const data = {
        subject,
        message,
      };
      await request("submitUserQuery", "POST", data);

      return;
    }

    messageBlurFn(true);
    subjectBlurFn(true);
  };

  return (
    <Form action="" method="" className="form">
      <Input
        type="text"
        className="mt-5"
        placeholder="Subject"
        value={subject}
        name="subject"
        onChange={(e) => subjectChangeFn(e.target.value)}
        onBlur={subjectBlurFn.bind(this, true)}
        autoComplete="off"
      />
      {subjectError && (
        <small style={{ color: "red" }}>Please enter a valid subject</small>
      )}
      <Input
        style={{ paddingLeft: "0.7rem", paddingBottom: "0.7rem" }}
        className="mt-4"
        value={message}
        placeholder="Message"
        type="textarea"
        onChange={(e) => messageChangeFn(e.target.value)}
        onBlur={messageBlurFn.bind(this, true)}
        name="message"
      />
      {messageError && (
        <small style={{ color: "red" }}>Please enter a valid message</small>
      )}
      <div className="m-5 text-center">
        <Button onClick={submit} className="ntn btn-primary">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default ContactUsPremium;
