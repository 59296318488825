import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import "components/custom.css";
import Header from '../../../components/pteportal/Header';
import Footer from '../../../components/pteportal/Footer';
import API from "api.js";
import { Redirect, NavLink } from "react-router-dom";
import { setToken, setUser, setSub} from 'action/index.js';


class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      otpflag:false
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async(e) => {
    
    this.setState({error_bag:null});
    e.preventDefault();
    let cred = {
      email: this.state.email,
      password: this.state.password,
    };
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/forgot',
        data: bodyFormData
      })
    }catch(error) {

        
    };
    if (res.status === 200) {
      this.setState({otpflag:true,error_bag:"OTP sent to your Email !"});
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\
        '; })
      this.setState({error_bag:str});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials."});
    }
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    const {token,setToken} = this.props;
    if (this.state.login) {
        return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <>
      <section class="header-with-banner">
        <Header />
        <section id="hero about-us-page" class="d-flex align-items-center">
        <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 mb-5">
              <h2 class="main-heading pt-5 text-center">Forgot Password </h2>
              <div class="breadcum">
                <ol class="breadcrumb primary-color text-center">
                    <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                    <li class="breadcrumb-item active">Forgot Password</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
    </section>
        </section>
        <main id="main" class="avout-page"> 
      <div className=" ">
        
        
<section class="contentOuter">
  <div class="container">
    <div class="row">
      <div class="col-sm-7 loginForm">
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
          <div class="fieldOuter">
            <Input 
                placeholder="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={(e) => { this.changeInput(e)} }
              />
              
          </div>
          
          <div class="rememberForgot">
            <div class="rememberMe">
              <input type="checkbox" name="" />
              <span>Remember me</span>
            </div>
            <div class="forgotPwd">
              <NavLink className="mt-5" to='/auth/login'><span>Back</span></NavLink>
            </div>
          </div>
          <div class="fieldOuter text-right">
            <Button
              className="themeBtn"
              onClick={(e) => {this.postLogIn(e)}}
            >
              Submit
            </Button>
            {this.state.otpflag && <NavLink to="/auth/resetPassword">Got OTP?</NavLink>}
          </div>
      </div>
      <div class="col-sm-5 registerLink text-center">
        <h3>New User?</h3>
        <p>Register now to obtain your<br /> Free Mock Test Material</p>
        <NavLink to="/auth/register" class="themeBtn blueBtn">Register</NavLink>
      </div>
    </div>
  </div>
</section>
        
      </div>
      </main>
      </>
    );
  }
}

export default Forgot;
