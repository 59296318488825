import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import API from "api";
import { CardHeader, CardTitle, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

const TestHeader = forwardRef(({ mockData, setMockData, id }, ref) => {
  const intStatus = useSelector((state) => state.intStatus);
  const [time, setTime] = useState(mockData.secondCount);
  const [qTime, setQTime] = useState(mockData.qTime);
  const currentQuestion = mockData.currentQuestion;
  const totalTime = mockData.secondCount;
  const tempRef = useRef(time);
  const mockRef = useRef(mockData);

  useEffect(() => {
    setTime(totalTime);
  }, [totalTime]);

  useEffect(() => {
    setQTime(0);
  }, [currentQuestion]);

  const timer = useCallback(() => {
    let connection;
    if (typeof intStatus === undefined || typeof intStatus === "undefined") {
      connection = true;
    } else {
      connection = intStatus;
    }

    if (connection) {
      if (!mockRef.current.internet) {
        setMockData((prevState) => {
          return { ...prevState, internet: true };
        });
      }

      if (tempRef.current <= 1) {
        let intervalId;
        setMockData((prevState) => {
          intervalId = prevState.intervalId;
          return {
            ...prevState,
            dashboardFlag: true,
            timeOutFlag: true,
            timeOut: true,
            stopRecording: true,
            pending: 1,
            nextType: 1,
            complete: 1,
            secondCount:
              tempRef.current === 0 ? tempRef.current + 1 : tempRef.current,
            qTime,
          };
        });
        let bodyFormData = new FormData();
        bodyFormData.append("mock_id", id);

        mockRef.current.ids.map((v, k) => {
          if (k !== 0) {
            // bodyFormData.append('id[]', v.id );
            bodyFormData.append("id[]", `${v.id}-${v.question_number}`);
          }
        });
        API({
          method: "Post",
          url: "set/mockTime",
          data: bodyFormData,
        });
        clearInterval(intervalId);
        return;
      }
      setTime((prevState) => {
        tempRef.current = prevState - 1;
        return prevState - 1;
      });
      setQTime((prevState) => prevState + 1);
    } else {
      if (mockRef.current.internet) {
        setMockData((prevState) => {
          return { ...prevState, internet: false };
        });
      }
    }
  }, [mockRef, intStatus]);

  useEffect(() => {
    let intervalId = setInterval(timer, 1000);
    setMockData((prevState) => {
      return { ...prevState, intervalId };
    });

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  useEffect(() => {
    mockRef.current = mockData;
  }, [mockData]);

  const callbackHandler = () => {
    return {
      timerData() {
        return { qTime, time };
      },
    };
  };

  useImperativeHandle(ref, callbackHandler);

  return (
    <CardHeader>
      <Row>
        <Col xs={6}>
          <CardTitle tag="h4">{mockData.title}</CardTitle>
        </Col>
        <Col xs={6}>
          <div className="text-right questionTime">
            <div className="timeRemaining">
              <p>
                <i className="fa fa-clock-o"></i>{" "}
                <span>
                  Time Remaining:{" "}
                  {Math.floor(time / 60) + ":" + parseInt(time % 60)}
                </span>
              </p>
            </div>
            <div className="item">
              <p>
                Question {mockData.currentQuestion + mockData.qCount + 1} of{" "}
                {mockData.questionData.length + mockData.qCount}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </CardHeader>
  );
});

export default TestHeader;
