import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from 'store/index';
import API from "api.js";
import { setToken, setUser, setLogo, setSub, setLoader } from 'action/index.js';


const mapStateToProps = (state) => {
	return {
		token: state.token
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setToken: (tok) => dispatch(setToken(tok)),
		setUser: (user) => dispatch(setUser(user)),
		setSub: (sub) => dispatch(setSub(sub)),
		setLogo: (sub) => dispatch(setLogo(sub))
	};
};

const CheckPost = (props) => {

	const useQuery= () => {
       return new URLSearchParams(useLocation().search);
	}

	let query = useQuery();
	useEffect(() => {
		console.log("first")
		// enable loader
		store.dispatch(setLoader(true));

		let cust = query.get('cust');
		API({
			method: 'GET',
			url: `/loginDataWithToken?cust=${cust}`,
		}).then((res) => {
			const expires = new Date();
			expires.setDate(Date.now() + 1000 * 60 * 60);

			props.setToken(res.data.access_token);
			props.setUser(res.data.user);
			props.setLogo(res.data.image);
			
			let sub = (res.data.user.active_subscription.length === 0) ? false : true;
      		props.setSub(sub);

      		// loader false.
      		store.dispatch(setLoader(false));
      		props.history.push('/admin/dashboard');

		}).catch(err => {
			store.dispatch(setLoader(false));
			this.notify(2, "Unable to process your request. Please try again from PTE academic dashboard.");
		});
	}, []);

	return "";
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckPost);