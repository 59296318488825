import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NotificationAlert = () => {
  const notifications = useSelector((state) => state.notifications);
  return (
    <>
      {notifications.length > 0 && (
        <div className="alert bg-danger row ml-0 mr-0">
          <div className="col-sm-12">
            <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> Hey
            there! You have {notifications.length} pending notifications
            awaiting your attention. Click{" "}
            <Link to="/admin/notifications" style={{ color: "white" }}>
              here
            </Link>{" "}
            to check and stay updated!
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationAlert;
