import React, { useState, useEffect } from "react";

export default function Unauthorized(props) {

	return (
		<>
			<div className="innerPage d-flex justify-content-center align-items-center auauthorizedAccess">
				<div className="row">
					<div className="col text-center">
						<i className="fa fa-lock"></i>
						<h3 className="text-center mt-3">Unauthorized Access Restricted.</h3>
					</div>
				</div>
			</div>
		</>
	);
}