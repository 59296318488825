import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

const BootstrapModal = ({
  status,
  toggle,
  message,
  data,
}) => {
  return (
    <Modal
      isOpen={status}
      size="lg"
    >
      <ModalBody>
        <span
          onClick={() => toggle()}
          style={{
            color: "#93c13e",
            opacity: "unset",
            position: "absolute",
            top: "4%",
            right: "3%",
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </span>
        {message && <h6>{message}</h6>}
        {data && <div style={{height: "450px"}} className="mt-4 text-center">{data}</div>}
      </ModalBody>
    </Modal>
  );
};

export default BootstrapModal;
