import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Alert,
  Col
} from "reactstrap";
import API from "api.js";
import { NavLink } from "react-router-dom";
import {store,persistor} from 'store/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Helmet } from "react-helmet";
import { thead, tbody } from "variables/general";

class PredictionFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember:true,
      loadmore:false,
      active_subscription:[]
    };
  }
   componentDidMount() {
    API({
      method: 'GET',
      url: 'user-data-get/active_subscription'
    }).then(data => {
      let active_subscription = data.data.user.active_subscription
      this.setState({active_subscription:active_subscription});
      if(active_subscription.length ===0 || active_subscription[0].package_id === 9){
        this.setState({paidMember:false});
      }else{
        this.setState({paidMember:true});
      }
    }).catch(()=>{
    });
    this.loadTemplate();
  }
 
 loadTemplate = () =>{
  API({
      method: 'GET',
      url: 'prediction/list?skip='+this.state.data.length
      }).then(data => {
        let loadmore = true;
        if (data.data.length < 10) {
          loadmore = false;
        }
        this.setState({'data':[...this.state.data,...data.data],loadmore:loadmore});
    }).catch(()=>{
        
    });
 }

  render() {
    return (
      <>
      <Helmet htmlAttributes>
        <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
               {!this.state.paidMember && <Alert color="success">Please upgrade your plan to access secret templates.</Alert>}
                <CardHeader>
                  <CardTitle tag="h4">Predictions</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table >
                    <thead className="text-primary">
                      <tr>
                        
                              <th className="">
                                Title
                              </th>
                              <th className="">
                                Action
                              </th>
                            
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((val, key) => {
                        return (
                          <tr key={key}>
                                  <td className="">
                                    {val.title}
                                  </td>
                                  <td className="">
                                    {this.state.paidMember && <NavLink
                                        to={'/admin/viewpdf/'+val.id}
                                        className="nav-link"
                                        activeClassName="active"
                                      >
                                        <i className="" />
                                        <p>View</p>
                                      </NavLink>}
                                    {!this.state.paidMember && <NavLink
                                        to={"/video-courses"}
                                        className="nav-link"
                                        activeClassName="active"
                                      >
                                        <i className="" />
                                        <p>Upgrade to paid plan</p>
                                      </NavLink>}
                                  </td>
                                
                          </tr>
                        );
                      })}
                    {this.state.loadmore && <button className="btn" onClick={(e)=>{this.loadTemplate();}}>Load more</button>}
                    </tbody>
                    
                  </Table>
                   
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default PredictionFiles;
