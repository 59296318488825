import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function UserSubscriptions(props) {
	const { subscription } = props;
	const [subscriptions, setSubscriptions] = useState(subscription.length > 0 ? subscription : null);
	return (
		<div className="upcomingClassBox d-flex flex-column mb-3 mySubs">
			<div className="classBox">
				<h4 className="m-0">My Subscription(s)</h4>
				<ul className="m-0 class-list">
					{subscriptions === null ? (
						<li className="show-more">
							<Link to="/admin/plans">Buy a plan</Link>
							<span></span>
						</li>
					) : (
						<>
							<li className="border-bottom mb-3">
								<a>Plan</a>
								<span>Expiry</span>
							</li>
							{subscriptions.map((item, i) => {
								return (
									<li key={i}>
										<a href="#">{item.plan.title}</a>
										{<span>{item.expiry_date}</span>}
									</li>
								)
							})}
						</>
					)}
				</ul>
			</div>
		</div>
	);
}