import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import API from "api.js";

export default function PopupExamDate(props) {
	const { open, setShowExamPopup, exam_date, set_exam_date, set_days_left } = props;
	
	const [isOpen, setIsOpen] = useState(open);
	const [examDate, setExamDate] = useState(exam_date !== null && exam_date !== undefined ? exam_date : '');

	const toggle = () => setIsOpen(!isOpen);

	const changeHandle = (event) => {
		setExamDate(event.target.value);
	}

	const submit = (event) => {
		API({
			url: "updateExamDate",
			method: "POST",
			data: { exam_date: examDate }
		}).then(res => {
			const { days_left } = res.data;
			set_exam_date(examDate);
			set_days_left(days_left);
			setShowExamPopup(false);
		}).catch(err => {
			console.log('err', err);
		});
	}

	return (
		<>
			{isOpen &&
				<Modal isOpen={isOpen}>
					<ModalHeader toggle={toggle}>Set Exam Date</ModalHeader>
					<ModalBody>
						<input
							type="date"
							value={examDate}
							onChange={changeHandle}
							className="form-control"
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => submit()}>Save Exam Date</Button>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}
