import React, { memo, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const SpeechToText = memo(function (props) {

	const { transcript, resetTranscript } = useSpeechRecognition();

	if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
		return null;
	}

	useEffect(() => {
		resetTranscript();
	}, []);

	useEffect(() => {
		if (props.saveTranscript) {
			props.setTranscript(transcript);
		}
	}, [props.saveTranscript]);

	return "";
});
export default SpeechToText;