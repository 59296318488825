import { useDispatch } from "react-redux";
import API from "../api";
import { setToken, setUser, setSub, expVidPopup } from "action/index.js";
import { useHistory } from "react-router-dom";

const useLogOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async () => {
    let response = await API({
      method: "POST",
      url: "logout",
    });

    if (response.status && response.status === 200) {
      dispatch(setToken(null));
      dispatch(setUser(null));
      dispatch(setSub(false));
      dispatch(expVidPopup(0));
      window.location.href = process.env.REACT_APP_LA_URL + "/auth/check";
    }
  };

  const logoutDirectly = () => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    dispatch(setSub(false));
    dispatch(expVidPopup(0));
    window.location.href = process.env.REACT_APP_LA_URL + "/auth/check";
  };

  return { logout, logoutDirectly };
};

export default useLogOut;
